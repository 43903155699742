/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --checkbox-label-color: #0a0a0a;
    --my-account-input-label-color: rgba(10, 10, 10, 0.59);
    --my-account-overlay-width: 100%;
    --my-account-overlay-border-width: 1px;
    --popup-bottom-offset: 40px;
    --popup-min-width: 450px;
}

.OnloadPopup {
    --overlay-background: var(--overlay-content-background);

    justify-self: flex-end;
    grid-column: 3;
    inset-inline-end: 0;
    padding-block-start: 12px;
    pointer-events: all;
    position: fixed;
    background-color: rgba(0,0,0,.2);
    background-color: var(--popup-background);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    width: 100vw;
    height: 100vh;
    inset-block-start: 0;
    z-index: 888;

    @include mobile {
        padding-block-start: 0;
    }

    @include desktop {
        position: fixed;
        inset-block-start: 0;
        inset-inline-end: 0;
        padding: 0;
        width: 100%;
        inset-inline-start: 0;
        // display: block;
        overflow: auto;
        align-items: baseline;
    }
    
    .Popup-mainWrapper {
        margin: 2rem auto;
        min-height: calc(100% - 4rem);
        display: flex;
        align-items: center;
        
        @include mobile {
            width: calc(100% - 20px);
        }
    }


    &-Heading {
        font-size: 24px;
        font-weight: 600;
        padding-block-start: 0;
        margin-block-end: 0;
        color: $default-primary-base-color;
        line-height: 31px;
        margin-block-start: -10px;

        @include mobile {
            padding-block-end: 10px;
            padding-inline: 0px;
            margin-block-start: 0;
        }
    }

    &-MainWrapper {
        margin: 2rem auto;
        min-height: calc(100% - 4rem);
        display: flex;
        align-items: center;
    }

    &-Content {
        background-color: #fff;
        border-radius: 0;
        padding: 0;
        max-width: 450px;
        min-width: 450px;
        overflow-y: auto;
        margin: 0 auto;
        max-height: none;

        @include mobile {
            min-width: auto;
        }

        @media (max-width: 767px) {
            width: 95%;
        }
    }

    &-CloseBtn {
        text-transform: capitalize;
        font-weight: 600;
        line-height: 16px;
        font-size: 12px;
        position: absolute;
        inset-block-start: 10px;
        z-index: 5;
        inset-inline-end: 10px;
        text-align: end;
        cursor: pointer;
        padding: 10px;

        @include mobile {
            inset-block-start: 0px;
            inset-inline-end: 0px;
        }
    }

    &-dark {
        background-color: #1D1D1D;
        color: #fff;
        padding: 30px;
        text-align: center;

        h2 {
            font-size: 30px;
            font-weight: 700;
            line-height: 42px;
            color: #fff;
            margin: 0;
            margin-block-end: 15px;
        }

        h6 {
            font-size: 17px;
            font-weight: 400;
        }
    }

    &-light {
        padding: 30px;
        padding-block-start: 0;

        @include mobile {
            padding: 10px;
        }

        input, button {
            width: 100%;
        }

        button {
            margin-block-start: 15px;
        }
    }
}


.Terms-condition { 
    font-size: 12px;
    color: $btn-primary-color;
    margin-block-start: 35px;
    margin-block-end: 22px;
    line-height: 20px;

    @include desktop {
        padding-inline-start: 35px;
        padding-inline-end: 35px;
        text-align: center;
        line-height: 1.5em;
        margin-block-end: 0 ;
        margin-block-start: 25px;
    }
    
    @include mobile {
        margin-block-start: 10px;
        // text-align: center;
        // padding: 0 20px;
        margin-block-end: 10px;
        text-align: center;
        padding: 0 20px;
    }

    a {
        font-size: inherit;
        font-weight: 400;
        color: inherit;
        text-underline-position: under;
        text-decoration: underline;
    }
}
