/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Footer {
    min-height: var(--footer-content-height);
    background-color: var(--secondary-base-color);
    padding-block-end: 40px;
    
    @include mobile {
        padding-block-end: 65px;
    }

    &-Columns {
        padding-block-start: 60px;   
    }

    &-Content {
        margin: auto;
        max-width: var(--content-wrapper-width);
        display: flex;
        flex-wrap: wrap;
        width: 100%; 
        padding: 30px 15px;

        @include desktop {
            padding: 60px 30px 0 30px;
        }

        @include mobile {
            flex-direction: column;
        }

        .footer-right {
            flex: 1 0 auto;
            display: flex;
            flex-wrap: wrap;
            padding-block-start: 10px;

            @include desktop {
                padding-block-start: 30px;
                width: 70%;
                justify-content: flex-end;
            }

            @include wide-desktop {
                padding-inline-start: 50px;
            }

            @include mobile {
                flex-direction: column;
            }

            .CmsBlock-Wrapper {
                @include desktop { 
                    padding-inline: 15px;
                    width: 28%;
                }
            }

            .Footer {
                &-Social {
                    @include desktop {  
                        width: 20%;
                    }

                    @include mobile {
                        margin-block-start: 15px;
                    }

                    .CmsBlock-Wrapper {
                        width: 100%;
                    }
                }
            }
            
            .Footer-ColumnWrapper {
                display: flex;
                justify-content: space-around;
                
                @include desktop {  
                    padding-inline: 15px;
                    width: 55%;
                }

                @include mobile {
                    flex-direction: column;
                }

                .ExpandableContent {
                    &-Content {
                        &_isContentExpanded {
                            @include mobile {
                                padding-block-end: 10px;
                            }
                        }
                    }
                }
            }

            .ExpandableContent-Heading {
                font-size: 12px;
                font-weight: 600;
                display: flex;
                align-items: center;

                @include mobile {
                    font-size: 14px;
                }

                .icon {
                    width: 20px;
                    margin-inline-end: 15px;
                    display: flex;
                    align-items: center;
                }
            }

            .ExpandableContent {
                &-Content {
                    a {
                        display: block;
                        color: $default-primary-base-color;
                        font-size: 12px;
                        margin-block-end: 3px;

                        @include mobile {
                            margin-block-end: 5px;
                            padding-inline-start: 35px;
                        }
                    }
                }

                .Menu-Link {
                    display: flex;
                    align-items: center;
                    
                    &_isIcon {
                        margin-block-end: 20px;
                    }

                    .Image {
                        height: 16px;
                        width: 16px;
                        padding: 0;
                        margin-inline-end: 12px;
                    }
                }
            }
        }
    }

    .Footer {
        &-logo {
            width: 100%;
            // height: 10px;
            max-width: var(--content-wrapper-width);
            margin-inline-start: auto;
            margin-inline-end: auto;
            margin-block-end: 10px; 

            @include desktop {
                // height: 10px;
            } 

            .Image-HeightFull {
                width: 130px;
                padding-block-end: 0;
                inset-block-start: 0px;
                z-index: 1;
                
                @include desktop {
                    // height: 10px;
                }

                img {
                    position: relative;
                }
            }
        }

        &-Copyright {
            padding-inline: 0;
            
            @include desktop {
                margin-block-start: 20px;
            }

            p {
                text-align: start;
                color: $btn-primary-color;
                font-size: 10px;
                line-height: 1.8em;

                a {
                    font-size: inherit;
                }

                span {
                    @include mobile {
                        display: block;
                    }
                }
            }
        }
    }

    .footer-payment-method {
        .payment-logo {
            width: 40px;
        }

        h4 {
            font-size: 12px;
            line-height: 1.3em;
            font-weight: 600;
            margin-block-start: 10px;
            margin-block-end: 0;
        }

        .payment-list {
            display: flex;
            list-style-type: none;
            margin-block-start: 10px;
            
            li {
                margin-block-end: 0;

                &::before {
                    display: none;
                }
            }
        }
    }

    .footer-support {
        @include mobile {
            margin-block-end: 15px;
        }

        .support-icon {
            display: flex;
            margin-block-end: 10px;
            align-items: center;

            img {
                width: 15px;
            }

            span {
                font-size: 12px;
                font-weight: 600;
                margin-inline-start: 18px;
            }
        }

        p {
            font-size: 12px;
            font-weight: 400;
            margin-inline-start: 32px;
        }
    }

    .social-link {
        h5 {
            font-size: 12px;
            font-weight: 600;
        }

        ul {
            margin-block-start: 12px;
            margin-block-end: 10px;

            li {
                &::before {
                    display: none;
                }

                .link {
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    font-weight: 400;
                    margin-block-end: 20px;

                    img {
                        width: 16px;
                        margin-inline-end: 10px;
                    }
                }
            }
        }
    }
}
