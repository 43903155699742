/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --checkbox-border-radius: var(--imported_checkboxes_border_radius, 0);
    --checkbox-border-color: var(--imported_checkboxes_border_color, var(--primary-light-color));
    --checkbox-border-color-active: var(--imported_checkboxes_border_color, var(--primary-light-color));
    --checkbox-width: var(--imported_checkboxes_width, 18px);
    --checkbox-height: var(--imported_checkboxes_height, 18px);
    --required-text-color: #dc6d6d;
}

label {
    &.Field {
        &-Label {
            font-size: 11px;
            font-weight: 400;
            color: $default-primary-light-color;
            padding-block-end: 0;
            position: relative;
            inset-block-start: 10px;
            inset-inline-start: 10px;
            padding: 0 5px;
            z-index: 1;
            background-color: $white;
        }
    }
}

.Field {
    &-Label {
        &_isRequired {
            color: inherit;
        }
    }

    [type='checkbox'], [type='radio'] {
        + .input-control {
            width: 16px;
            height: 16px;
            border: 1px solid $border-color;
        
            &::after { 
                box-shadow: 2px 0 0 var(--checkmark-color),
                    4px 0 0 var(--checkmark-color),
                    4px -2px 0 var(--checkmark-color),
                    4px -4px 0 var(--checkmark-color),
                    4px -6px 0 var(--checkmark-color),
                    4px -8px 0 var(--checkmark-color);
            }
        }
    }

    select {
        font-size: 12px;
    }

    &_type {
        &_select {
            &.Field_isValid {
                select {
                    border: 1px solid $border-color;
                }
            }
        }

        &_text,
        &_textarea,
        &_email,
        &_password,
        &_date,
        &_time,
        &_datetime,
        &_datetime-local,
        &_file,
        &_select {
            &.Field_isValid {
                input,
                textarea,
                select {
                    border: 1px solid $default-primary-light-color;
                }
            }
        }
    }

    &_hasError {
        input:not([type='number']),
        textarea,
        select {
            border: 1px solid var(--primary-error-color);
        }

        .Field-Label {
            /* stylelint-disable-next-line declaration-no-important */
            color: var(--primary-error-color) !important;
        }
    }  
}

.city_isDefault_hasNotError, .state_isDefault_hasNotError {
    input, select {
        /* stylelint-disable-next-line declaration-no-important */
        border-color: #000 !important;
    }

    .Field-LabelContainer {
        .Field-Label {
            /* stylelint-disable-next-line declaration-no-important */
            color: #000 !important;
        }
    } 

    + .Field-ErrorMessages {
        display: none; 
    }
}


.Field_type_text {
    .input-group {
        display: flex;
        flex-wrap: wrap;

        .input-prepone {
            display: flex;
            align-items: center;
            padding: 0.5rem 0.5rem;
            background-color: #fff;
            border-right: 0px;
            position: absolute;
            inset-block-start: 10px;
            inset-inline-start: 8px;
            z-index: 2;
            font-size: 13px;
            font-weight: 600;
            
            @include mobile {
                inset-block-start: 9px;
            }
        }

        input {
            padding-inline-start: 40px;
        }
    }
}

.Password {
    &-errorMessage {
        color: var(--primary-error-color);
        font-size: 12px;

        ul {
            margin-block-start: 10px;

            li {
                color: var(--primary-error-color);
                font-size: 12px;
                line-height: 1;
                padding-inline-start: 15px;

                &::before {
                    content: '';
                    inset-block-start: 3px;
                    inset-inline-start: 0;
                    width: 4px;
                    height: 4px;
                    border-radius: 5px;
                    background-color: var(--primary-error-color);
                    padding: 0;
                }
            }
        }
    }
}
