/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.NewsletterSubscription {
    @include desktop {
        width: 30%;
    }

    h4 {
        font-size: 16px;
        font-weight: 600;
        margin-block-end: 12px;
    }

    p {
        font-size: 12px;
    }

    .Form {
        .FieldForm-Fieldset {
            position: relative;

            .Field {
                input {
                    background-color: transparent;
                    border-radius: 4px;
                }

                .Field-LabelContainer {
                    position: absolute;
                    inset-block-start: -4px;
                }
                
                .Field-Label {
                    min-width: auto;
                    background-color: var(--secondary-base-color);
                    inset-block-start: 0;
                    font-weight: 600;
                }
            }

            .Button {
                position: absolute;
                inset-block-start: 0;
                inset-inline-end: 0;
                background: transparent;
                border: none;
                width: auto;
                margin-block-start: 0;
                font-size: 11px;
                font-weight: 600;
                text-transform: capitalize;
                color: #434343;
                
                @include mobile {
                    width: auto;
                }
            }
        }
    }

    .Terms-condition {
        font-size: 10px;
        line-height: 1.8em;
        color: $btn-primary-color;
        margin-block-start: 15px;
        margin-block-end: 40px;

        a {
            font-size: inherit;
            color: inherit;
            font-weight: 400;
            text-decoration: underline;
        }
    }

    .NewsletterSubscription {
        margin-block-start: 25px;
        width: 100%;
    }
}

.payment-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;

    li {
        img {
            width: 40px;
        }
    }
}
