/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.TrackOrderStatus {
    padding-block: 25px;
    @include desktop {
        max-width: 950px;
        margin-inline: auto;
    }
    &-StatusWrapper {
        display: flex;
        flex-wrap: wrap;
        margin-block-end: 35px;
    }

    &-Status {
        display: flex;
        flex-wrap: wrap;
        @include desktop {
            width: 65%;
        }

        @include mobile {
            flex-direction: column;
            width: 100%;
        }

        .HideButton, .ShowButton {
            @include mobile {
                border: 1px solid black;
                color: white;
                background-color: black;
                letter-spacing: 1.5px;
                padding-block-start: 14px;
                padding-block-end: 10px;
                margin-block-end: 20px;
                margin-inline: 20px;
            }
        }
    }

    &-ExpectedDelivery {
        flex: 1;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        padding-inline: 20px;
        @include desktop {
            background: url('../../image/desktop-expected-delivery.jpg') no-repeat;
            background-size: cover;
        }
        @include mobile {
            background: url('../../image/mobile-expected-delivery.png') no-repeat;
            background-size: cover;
        }
    }

    &-delivered {
        flex: 1;
        max-height: 525px;
        overflow-y: auto;
        padding: 10px 20px;

        @include mobile {
            padding: 20px 0 0;
            min-height: 320px;
        }

        .Status {
            font-size: 15px;
            color: red;
            text-align: center;
            text-transform: uppercase;
            border-bottom: 1px solid $border-color;
            padding-block-end: 10px;
            font-weight: 400;
            letter-spacing: 1.5px;
        }

        .Delivered {
            &-StatusTitle {
                font-size: 30px;
                letter-spacing: 1.2px;
                padding: 25px 0px;
            }
        }

        h5 {
            font-size: 15px;
            padding-block-end: 10px;
            letter-spacing: 1px;
        }

        .TrackingNumber {
            display: flex;
            flex-direction: column;
            border-top: 1px solid $border-color;
            padding-block-start: 24px;
            padding-block-end: 24px;
            
            @include mobile {
                padding-block-start: 20px;
                padding-block-end: 20px;
            }

            &-Title {
                font-size: 15px
            }

            &-Code {
                font-size: 16px;
                padding-block-start: 2px;
                color: #337ab7;
                font-weight: 400;
            }
        }

        .TrackOrderStatus {
            &-List {
                border-top: 1px solid $border-color;
                padding-block-start: 22px;
                padding-block-end: 22px;
                font-size: 14px;
                color: $light-primary-color;
                display: flex;
                
                @include mobile {
                    margin-inline: 20px;
                }
                
                .DateTimeWrapper {
                    display: flex;
                    flex-direction: column;
                    margin-inline-end: 20px;
                }
            }
        }
    }

    &-ShopProject {
        
        @include desktop {
            width: 35%;
        }
    }

    &-DeliveredStatus {
        background-color: $white;
        width: 120px;
        padding-block: 25px;
        text-align: center;
        
        @include mobile {
            margin-block-end: 15px;
        }

        .Title {
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 700;
            letter-spacing: 0.5px;
        }

        .Month {
            font-size: 22px;
            font-weight: 400;
            margin-block-start: 5px;
        }

        .Date {
            margin: 12px 0 0;
            font-size: 60px;
            color: red;

            @include mobile {
                font-size: 50px;
                line-height: 40px;
            }
        }
    }

    &-Message {
        color: $white;
        margin-block-start: auto;
        font-size: 28px;
        font-weight: 600;
        line-height: 1.3em;
        margin-block-end: 25px;
        letter-spacing: 0.5px;

        @include desktop {
            margin-block-end: 35px;
            font-weight: 700;
        }
    }

    &-ShopFor {
        .pagebuilder-column-group {
            gap:15px;
            @include mobile {
                flex-direction: column;
                gap: 5px;
            }

            .pagebuilder-column {
                @include mobile {
                    width: 100% !important;
                }
            }
        }
    }

    .DeliveredStatus {
        &-Wrapper {
            @include mobile {
                margin-inline: 20px;
            }
        }
    }
}
