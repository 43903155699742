/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ZipcodeServiceabilityCheck {
    margin-block-end: 35px;

    &-Message {
        display: inline-block;
    }

    &-Input {
        display: inline-grid;
        margin-block-start: 0;

        input {
            width: 222px;
            border-radius: 0;
            
            @media screen and (max-width: 480px) {
                width: 155px;
            }
        }
    }

    &-Button {
        @include button;
        
        border-width: 1px;
        border-radius: 4px;
        margin-inline-start: 5px;
        width: 25%;

        @include mobile {
            width: 25%;
        }
    }

    &-Title {
        font-size: 15px;
        color: $default-primary-base-color;
        line-height: 20px;
        font-weight: 700;
        margin: 0;
        padding: 0;
        margin-block-end: 10px;
    }

    

    &-Availability {
        margin-block-start: 10px;

        span {
            font-size: 14px;
            line-height: 18px;
            
            &.success {
                color: #118821;
            }

            &.error {
                color: red;
            }
        }
    }

    &-Wrapper {
        margin-block-end: 35px;

        form {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            .Field {
                margin: 0;
            }

            .Field-Wrapper {
                width: 75%;

                input {
                    width: 100%;
                }
            }
        }
    }
}
