/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CampaigningPage {
    width: 100%;

    .CmsPage {
        min-height: inherit;

        .CmsPage-Wrapper {
            margin: 0;
        }
    }

    .campaigning-page-hero-banner {
        margin-block-end: 35px;

        .web-banner, .mobile-banner {
            /* stylelint-disable-next-line declaration-no-important */
            display: none !important;
        }

        .web-banner {
            /* stylelint-disable-next-line declaration-no-important */
            display: flex !important;
  
            @include mobile {
                /* stylelint-disable-next-line declaration-no-important */
                display: none !important;
            }
        }

        .mobile-banner {
            @include mobile {
                /* stylelint-disable-next-line declaration-no-important */
                display: flex !important;
            }
        }
    }

    .campaigning-page-details {
        h3 {
            margin: 0;
            text-align: center;

            span {
                background-color: #dc0019;
                color: #ffffff;
                text-transform: uppercase;
                padding: 8px;
                font-size: 51px;
                font-weight: 800;
                margin-block-end: 35px;
                display: inline-block;
                line-height: 63px;

                @media screen and (max-width: 600px) {
                    font-size: 40px;
                    line-height: 52px;
                }
            }
        }

        .participant-text {
            p {
                margin: 0;
                font-size: 25px;
                margin-block-end: 35px;

                @media screen and (max-width: 600px) {
                    font-size: 21px;
                    line-height: 32px;
                }
            }
        }

        .pagebuilder-column-group {
            margin-block-end: 35px;

            @include mobile {
                /* stylelint-disable-next-line declaration-no-important */
                display: block !important;
            }

            .pagebuilder-column {
                min-height: 350px;
                padding: 50px;

                @include mobile {
                    /* stylelint-disable-next-line declaration-no-important */
                    width: 100% !important;
                    min-height: auto;
                }

                h5 {
                    font-size: 51px;
                    font-weight: 800;
                    line-height: 51px;

                    @media screen and (max-width: 600px) {
                        font-size: 40px;
                        line-height: 52px;
                    }
                }

                p {
                    margin: 0;
                    padding: 0;
                    font-size: 20px;
                    line-height: 30px;
                    min-height: 140px;

                    @include mobile {
                        min-height: auto;
                    }
                }

                &.white-text {
                    color: #fff;

                    p {
                        color: #fff;
                    }
                }
            }
        }
    }

    .campaigning-page-bottom-banner {
        margin-block-start: 35px;
        text-align: center;

        @media screen and (max-width: 800px) {
            padding: 0 20px;
        }

        figure {
            margin-block-end: 25px;
        }

        h1, h2 {
            font-size: 64px;
            font-family: $font-NeuePlakBlack-Condensed;
            letter-spacing: 2px;
            line-height: 1em;
            text-transform: uppercase;
            margin: 0;
            margin-block-end: 25px;
            color: #000;
            
            @media(max-width: 1100px) {
                font-size: 45px;
            }
    
            @include mobile {
                font-size: 35px;
                text-align: center;
                color: #000;
            }
    
            @media (max-width: 370px) {
                font-size: 28px;
            }
        }
    
        p {
            text-align: center;
            margin: 0 auto;
            font-size: 17px;
            line-height: 26px;
            margin-block-end: 12px;
            margin-block-start: 12px;

            @media screen and (max-width: 600px) {
                padding: 0 20px;
            }
    
            // @include desktop {
            //     max-width: 508px;
            // }
    
            // @media (max-width: 900px) {
            //     max-width: 100%;
            //     font-weight: 600;
            // }
        }
    }

    .stay-tuned {
        margin-block-start: 25px;
        background-color: #000;
        text-align: center;

        @media screen and (max-width: 600px) {
            padding: 0 20px;
        }

        p {
            font-weight: 600;
            font-size: 18px;
            color: #fff;
            margin: 0;
            padding: 12px 0;

            @media screen and (max-width: 600px) {
                line-height: 28px;
            }

            a {
                font-weight: 600;
                font-size: 18px;
                color: #fff;
                text-decoration: underline;
            }
        }
    }
}
