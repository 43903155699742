/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.StoreLocator {
    h1 {
        letter-spacing: 1.5px;
        margin-block-end: 25px;
        width: 100%;
        text-align: center;

        @include mobile {
            margin-block: 0;

        }
    }

    &-Wrapper {
        width: 100%;
        padding-block-start: 40px;
        padding-block-end: 40px;
        max-width: 100%;

        @include desktop {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &-Form {
        
        @include desktop {
            width: 35%;
        }

        @include wide-desktop {
            width: 445px;
        }

        input {
            width: 100%;
            border-radius: 0px;
            margin-block-end: 18px;

            @include mobile {
                // margin-block-end:  0;
            }
        }

        .Button {
            width: 100%;
            background-color: $addtobag-backgorund-color;
            border-radius: 0px ;
            border: 0px;
            font-weight: 600;
            letter-spacing: 1.5px;
            margin-block-start: 0;

            &:hover {
                background-color: $addtobag-backgorund-color;
            }
        }
        
        .Field {
            @include desktop {
                margin-block-start: 0;
            }
            
        }
    }

    .StoreLocatorContent {
        &-Data {
            .StoreLocatorContent {
                &-Header {
                    text-align: center;
                    font-weight: 700;
                    margin-block-start: 30px;
                    border-top: 1px solid $border-color;
                    border-bottom: 1px solid $border-color;
                    padding-block-start: 12px;
                    padding-block-end: 12px;
                }
            }

            .StoreLocatorContent {
                &-Content {
                    li {
                        border-bottom: 1px solid $border-color ;
                        padding-block-start: 20px;
                        padding-block-end: 20px;

                        &:before {
                            display: none;
                        }

                        .Phone_number {
                            font-size: 14px;
                            a {
                                color: black;
                                background-color: transparent;
                                border: none;
                                padding: 0px;
                                margin-inline-start: 5px;
                                font-weight: normal;
                            }
                        }

                        a {
                            border: 1px solid black;
                            color: white;
                            background-color: black;
                            border-radius: 3px;
                            letter-spacing: 1.5px;
                            padding: 3px;
                            font-weight: 700;

                            &:hover {
                                background-color: white;
                                color: black;
                            }

                        }

                        h4 {
                            font-weight: 700;
                            font-size: 15px;
                            margin-block-end: 10px;
                        }
                    }
                }
            }
        }
    }

    &-Map {

        @include desktop {
            width: 65%;
        }
        
        @include wide-desktop {
            width: calc(100% - 445px);
        }

        .Map {
            height: 400px;
            width: 100%;
            
            @include mobile {
                height: 200px;
            }

            @include desktop {
                padding-inline-start: 20px;
                height: 500px;
            }

            @include wide-desktop {
                height: 700px;
            }
        }
    }

    .ShowMore {
        &_isActive {
            display: none;
        }
    }
}
