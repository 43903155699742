/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MenuItem {
    // TODO style the element
}

.Menu {
    &-MenuWrapper {
        .Menu {
            &-SubCategoriesWrapper {
                position: fixed;
                inset-inline-start: 0px;
                width: 100%;
                background: rgba(0,0,0,0.5);
                height: 100%;

                .Menu {
                    &-SubCategories {
                        .Menu {
                            &-ItemCaption {
                                &.Menu {
                                    &-ItemCaption {
                                        &_type {
                                            &_subcategory {
                                                color: $default-primary-base-color;
                                                font-size: 16px;
                                                font-weight: 600;
                                                padding-block-end: 0px;
                                                margin-block-end: 15px;
                                                pointer-events: none;
                                                
                                                &::after {
                                                    background-color: $default-primary-base-color;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            &-SubLevelDesktop {
                                .Menu {
                                    &-ItemList {
                                        .Menu {
                                            &-Link {
                                                display: block;
                                                margin-block-end: 10px;
                                            }

                                            &-ItemCaption {
                                                color: $btn-primary-color;
                                                font-size: 12px;
                                                font-weight: 600;
                                                display: inline-block;
                                                padding-block-end: 0px;
                                                
                                                &:hover {
                                                    color: $default-primary-base-color;
                                                }

                                                &::after {
                                                    background-color: $default-primary-base-color;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &-SubCategoriesWrapperInner {
        @include desktop {
            background: $white;
            padding: 35px 40px;
            max-width: var(--content-wrapper-width);
            margin: 0 auto;
        }
    }
}
