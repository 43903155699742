/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
* ScandiPWA - Progressive Web App for Magento
*
* Copyright © Scandiweb, Inc. All rights reserved.
* See LICENSE for license details.
*
* @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
* @package scandipwa/base-theme
* @link https://github.com/scandipwa/base-theme
*/

.ChevronIcon {
    fill: var(--color-black);
    cursor: pointer;

    &_direction {
        &_left {
            transform: rotate(90deg);

            [dir="rtl"] & {
                transform: none;
            }
        }

        &_right {
            transform: rotate(-90deg);
            
            [dir="rtl"] & {
                transform: rotate(90deg);
            }
        }

        &_bottom {
            transform: rotate(0deg);
        }

        &_top {
            transform: rotate(-180deg);
        }
    }
}
