/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.CookiePopup {
    --cookie-popup-bottom-margin: 10px;

    background-color: #1d1d1d;
    position: fixed;
    inset-block-end: 0;
    display: flex;
    align-items: center;
    width: 100%;
    z-index: 999999;

    @include desktop {
        padding: 30px;
    }

    @include mobile {
        inset-block-end: 0;
        flex-direction: column;
        width: 100%;
        margin-inline: 0;
        padding: 16px;
    }

    &-Content {
        color: #fff;
        font-size: 14px;
        margin-block-end: 0;
        text-align: center;

        &::after {
            content: '.';
            color: var(--color-white);
        }

        a {
            font-weight: normal;
        }

        @include mobile {
            font-size: 11px;
            text-align: start;
        }
    }

    &-Link {
        color: #fff;
        font-size: 14px;
        text-decoration: underline;
        cursor: pointer;

        @include mobile {
            font-size: 11px;
        }
    }

    a:hover {
        color: inherit;
    }

    &-CTA {
        color: #fff;
        text-transform: uppercase;
        font-size: 27px;
        cursor: pointer;
        white-space: nowrap;
        line-height: 15px;

        @include desktop {
            margin-inline-start: 41px;
        }

        @include mobile {
            margin-block-start: 12px;
        }

        &:hover {
            color: var(--color-white);
        }
    }
}


// .HomePage + .Footer + .CookiePopup {
//     display: flex;
// }
