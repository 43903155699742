/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.FaqsPage {
   &-FaqsWrapper {
       margin-block-end: 40px;

       @include desktop {
           display: flex;
           align-items: flex-start;
       }
   }

   &-SideMenu {
       width: 25%;
       position: sticky;
       top: 150px;
       padding-inline-end: 15px;

       .Item {
            color:$light-primary-color;
            padding: 10px 0;
            border-bottom: 1px solid $border-color;
            display: block;
            font-weight: 400;
            font-size: 14px;
            cursor: pointer;

           &::before {
               display: none;
           }

           a {
               color:$light-primary-color;
               padding: 10px 0;
               border-bottom: 1px solid $border-color;
               display: block;
               font-weight: 400;
               font-size: 14px;
           }
       }
   }

   &-PageTitle {
       text-align: center;
       margin-block-start: 15px;
       font-size: 47px;
   }

   &-ContentDetail {
       
       @include desktop {
           padding-inline-start: 20px;
           padding-inline-end: 30px;
            width: 50%;
        }

        h1, h2 {
           font-weight: 700;
       }

      .ExpandableContent {
            @include desktop {
                border-top: 1px solid var(--expandable-content-divider-color);
                
            }
            
            &:last-of-type {
                @include desktop {
                //    border-bottom: 1px solid var(--expandable-content-divider-color);
                }
            } 

          &-Content {
              @include desktop {
                max-height: 0;
                overflow: hidden;
                opacity: 0;
                margin-block-start: 0;
              }

              .Answers {
                margin-block-end: 15px;
                font-size: 12px;

                a {
                    font-size: inherit;
                    font-weight: 400;
                    color: #014261;
                    text-decoration: underline;
                }

                  @include desktop {                      
                    padding-inline-start: 15px;
                    font-size: 14px;
                  }
                  
                }
              
              &_isContentExpanded {
                @include desktop {
                    opacity: 1;
                    max-height: 100%;
                }
              }
            }

            &-Button {
                @include desktop {
                    padding-block: 18px;
                    cursor: pointer;
                }
                

                &:after {
                    @include desktop {
                        content: '';
                        width: 12px;
                        height: 12px;
                        border-top: 2px solid $default-primary-base-color;
                        border-right: 2px solid $default-primary-base-color;
                        transform: rotate(135deg);
                    }
                }

                &_isContentExpanded {
                    &:after {
                        @include desktop {
                            transform: rotate(-45deg); 
                        }
                    }
                }
            }

            &-Heading {
                @include desktop {
                    font-size: 21px;
                    font-weight: 500;
                }
            }
        } 
    }

   .contact-information {
       margin-block-start: 30px;

        @include desktop {
            border-left: 1px solid $border-color;
            padding-left: 10px;
            margin-block-start:100px;
        }

    .phone-support , .email-support {
        .icon__circle {
            width: 30px;
            height: 30px;
            background-color: $default-primary-base-color;
            fill: $white;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            position: relative;
            stroke-width: 25;
            border-radius: 50%;

            svg {
                width: calc(100% - 15px);
                height: calc(100% - 15px);
            }
        }
    }

    .support-info {
        display: grid;
        line-height: 18px;
        margin-block-start: 10px;
        font-size: 12px;

        a {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 400;
            font-size: 12px;
            text-decoration: underline;
        }
    }
}
}
