/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyOrderTab {
    .ProductTabs {
        display: flex;
        border-block-end: 1px solid $border-color;
    
        @include desktop {
            display: flex;
        }
    
        &-Wrapper {
            padding-inline: 0;

            @include mobile {
                padding: 0;
            }
    
            .ExpandableContent-Heading {
                text-transform: uppercase;
            }
    
            .ExpandableContent {
                border-block-end: 0;
            }
    
            & > :first-child {
                border-block-start: 0;
            }
        }

        .ProductTab {
            &-Item {
                color: $default-primary-base-color;
                text-decoration: none;
                letter-spacing: 1/2px;
                padding: 20px 0;
                margin-block-end: 0;

                @include desktop {
                    margin-inline-end: 50px;
                }

                @include mobile {
                    flex: 1 1;
                    text-align: center;
                }

                &::before {
                    display: none;
                }

                &_isActive {
                    background-color: transparent;

                    &::after {
                        content: '';
                        position: absolute;
                        inset-block-end: 0;
                        width: 100%;
                        height: 2px;
                        background-color: $default-primary-base-color;
                        inset-inline-start: 0;
                    }
                }
            }

            a {
                font-size: 16px;
            }

            &-Button {
                color: $default-primary-base-color;
                text-decoration: none;
                letter-spacing: 0.25;
                padding: 0;
                font-size: 16px;

                @include mobile {
                    width: 100%;
                }

                a {
                    font-size: inherit
                }
            }
        }
    }
}
