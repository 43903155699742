/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.SearchField {
    width: 170px;
    margin-inline-end: 0;
    
    @include mobile {
        width: 100%;
    }

    &-Input {
        background-color: transparent;
        border: 0;
        border-bottom: 1px solid $drakGray-color;
        font-size: 16px;
        font-weight: 600;
        padding: 0;
        line-height: 1em;
        border-radius: 0;
        color: $drakGray-color;
        caret-color: white;
        height: auto;
        
        &:focus {
            border-bottom: 1px solid $drakGray-color;
            
            &::placeholder {
                color: transparent;
            }
        }
    }
    
    &-CloseIcon {
        @include mobile {
            height: 12px;
            width: 12px;
            inset-block-start: calc(50% - 8px);
        }
    }

    &-SearchIcon,
    &-CloseIcon {
        inset-inline-end: 0;
        color: $drakGray-color;
        width: 18px;

        @include mobile {
            height: 14px;
            width: 14px;
            inset-block-start: calc(50% - 10px);
        }

        .SearchIcon,
        .CloseIcon {
            fill: $drakGray-color;
            height: 18px;
            width: 18px;
        }
    }
}
