@font-face {
    font-family: "NeuePlak";
    src: url(/style/fonts/NeuePlak-SemiBold.woff2) format("woff2"),
        url(/style/fonts/NeuePlak-SemiBold.woff) format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "NeuePlak";
    src: url(/style/fonts/NeuePlak-Black.woff2) format("woff2"), url(/style/fonts/NeuePlak-Black.woff) format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Plak Condensed Black-1';
    src: url(/style/fonts/Neue-Plak-Condensed-Black.eot);
    src: url(/style/fonts/Neue-Plak-Condensed-Black.eot?#iefix) format('embedded-opentype'), url(/style/fonts/Neue-Plak-Condensed-Black.woff2) format('woff2'),  url(/style/fonts/Neue-Plak-Condensed-Black.woff) format('woff'), url(/style/fonts/Neue-Plak-Condensed-Black.ttf)  format('truetype');
}

@font-face {
    font-family: 'Neue Plak Condensed';
    src: url(/style/fonts/NeuePlak-CondRegular.eot);
    src: url(/style/fonts/NeuePlak-CondRegular.eot?#iefix) format('embedded-opentype'),
        url(/style/fonts/NeuePlak-CondRegular.woff2) format('woff2'),
        url(/style/fonts/NeuePlak-CondRegular.woff) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Plak Condensed';
    src: url(/style/fonts/NeuePlak-CondBlack.woff2) format('woff2'),
        url(/style/fonts/NeuePlak-CondBlack.woff) format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "NeuePlak";
    src: url(/style/fonts/NeuePlak-Bold.woff2) format("woff2"), url(/style/fonts/NeuePlak-Bold.woff) format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "NeuePlak";
    src: url(/style/fonts/NeuePlak-Regular.woff2) format("woff2"),
        url(/style/fonts/NeuePlak-Regular.woff) format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "NeuePlak";
    src: url(/style/fonts/NeuePlak-Light.woff2) format("woff2"), url(/style/fonts/NeuePlak-Light.woff) format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

$font-NeuePlak: "NeuePlak";
$font-NeuePlakLight: "NeuePlakLight", sans-serif;
$font-NeuePlakBold: "NeuePlakBold", sans-serif;
$font-NeuePlakBlack: "NeuePlakBlack", sans-serif;
$font-NeuePlakSemiBold: "NeuePlakSemiBold", sans-serif;
$font-NeuePlakCondensedBlack: "Neue Plak Condensed Black-1", sans-serif;

body {
    font-family: $font-NeuePlak;
    min-height: 100%;
    font-weight: 400;
}

html {
    height: 100%;
}
