/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.TrackOrder {
    &-Wrapper {
        padding-block-start: 30px;

        @include desktop {
            display: flex;
            align-items: center; 
            flex-wrap: wrap;
        }
    }

    &-Content {
        @include desktop {
            width: 45%;
            padding-inline-end: 50px;
        }

        .CmsPage {
            min-height: inherit;

            .CmsPage-Wrapper {
                margin: 0;
            }
        }
    }

    &-Image {
        @include desktop {
            width: 55%;
        }
    }

    &-Submit {
        width: 100%;
        margin-block-start: 25px;

        button {
            width: 100%;
        }

        .Button[disabled] {
            opacity: 1;
        }
    }

    .Field {
        input {
            width: 100%;
        }
    }

    &-ViewOrderHistory {
        text-align:  center;
        margin-block-start: 25px;
        margin-block-end: 25px;

        .Button {
            font-weight: 600;
        }
    }
}
