/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.SizeGuideButton {
    button {
        margin-block-end: 20px;
        color: $light-primary-color;
        text-decoration: underline;
        cursor: pointer;
        font-weight: 600;
        font-size: 14px;
        
        @include mobile {
            margin-block-start: 15px;
        }
    }
}   
