/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.NotifyMe {
    &-Wrapper {
        align-items: center;
        display: flex;
        margin-block-start: 24px;
        border-top: 1px solid $border-color;
        padding-block-start: 30px;
        margin-block-end: 35px;

        @media (min-width: 1001px) and (max-width: 1160px) {
            justify-content: flex-start;
        }

        button {
            background-color: $addtobag-backgorund-color;
            border: none;
            font-size: 12px;
            text-transform: none;
            border-radius: 4px;
            min-width: 180px;
            margin-block-start: 0;
            font-weight: 600;
            width: 100%;
        }
    }

    &-WrapperFixed {
        position: fixed;
        inset-block-end: var(--footer-total-height);
        z-index: 5;
        background-color: var(--color-white);
        width: 100%;
        display: flex;
        padding: 12px 16px;
        border-block-start: 1px solid var(--primary-divider-color);
        inset-inline-start: 0;

        @media (max-width: 450px) {
            padding-inline-end: 90px;
        }

        .hideOnScroll & {
            transform: translateY(var(--footer-nav-height));
        }

        button {
            background-color: $addtobag-backgorund-color;
            border: none;
            font-size: 12px;
            text-transform: none;
            border-radius: 4px;
            min-width: 180px;
            margin-block-start: 0;
            font-weight: 600;
            width: 100%;
        }
    }

    &-OOSText {
        color: red;
        font-size: 16px;
        font-weight: 600;
    }
}
