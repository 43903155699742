/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/* stylelint-disable declaration-no-important */
.BrandCmsPage {
    .brands-banner {
        position: relative;

        .pagebuilder-banner-wrapper {
            @include mobile {
                min-height: 300px !important;
                background-position: center !important;
            }
        }

        .brands-content {
            @include desktop {
                position: absolute;
                right: 10%;
                top: 50%;
                transform: translateY(-50%);
                color: $white;
                text-align: center;
            }

            @include mobile {
                margin-block-end: 20px;
            }

            h3 {
                font-family: $font-NeuePlakBlack-Condensed;
                letter-spacing: 2px;
                line-height: 1em;
                font-weight: 400;
                margin-block-end: 0;
                font-size: 18px;

                @include desktop {
                    color: $white;
                    font-size: 44px;
                }

                @include mobile {
                    text-align: center;
                }
            }

            h2 {
                letter-spacing: 2px;
                font-family: $font-NeuePlakBlack-Condensed;
                line-height: 1em;
                margin-block-start: 10px;
                font-size: 28px;

                @include desktop {
                    color: $white;
                    font-size: 64px;
                }
            }
        }
    }

    .brands_Anchar_tabs {
        padding: 65px 0;
        
        @include mobile {
            padding: 45px 15px 15px;
        }

        h2 {
            font-size: 40px;
            font-family: $font-NeuePlakBlack-Condensed;
            color: $white;
            margin-block-end: 5px;
            margin-block-start: 0;

            @include mobile {
                font-size: 28px;
                margin-block-end: 10px;
            }
        }

        .min-height-60 {
            @include desktop {
                min-height: 60px;
            }
        }
        
        p {
            font-size: 20px;
            font-weight: 700;
            line-height: 1.2em;

            @include mobile {
                font-size: 14px;
            }
        }

        .our-value, .our-story {
            border: 1px solid $white;
            border-radius: 4px;
            color: $white;
            font-size: 14px;
            font-weight: 600;
            min-width: 200px;
            cursor: pointer;
            width: 200px;
            margin-inline: auto;
            min-height: 44px;
            margin-bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            
            @include mobile {
                margin-block-end: 30px;
                margin-block-start: 10px;
            }

            &:hover {
                background-color: rgba($color: #000000, $alpha: 0.5);
            }
        }
    }

    .video_banner {
        padding-block: 30px;

        @include desktop {
            padding: 100px 100px;
        }
    }

    .our-value_content {
        margin-inline: auto;
        padding-block-start: 30px;
        
        @include desktop {
            max-width: 50%;
        }

        h2 {
            font-size: 64px;
            font-family: $font-NeuePlakBlack-Condensed;

            @include mobile {
                font-size: 28px;
            }
        }
 
        p {
            font-size: 16px;
            text-align: center;
            color: $light-primary-color;
            line-height: 1.4em;

            @include mobile {
                font-size: 12px;
            }
        }
    }

    .our-value-list {
        margin-inline: auto;
        
        @include mobile {
            padding-inline: 15px;
        }

        @include desktop {
            margin-block: 50px;
            width: 65%;
        }

        figure {
            text-align: center;
        }

        h3 {
            font-size: 40px;
            font-family: $font-NeuePlakBlack-Condensed;

            @include mobile {
                font-size: 22px;
                margin-block-end: 10px;
            }
        }

        ul {
            padding-block-start: 5px;

            li {
                font-size: 16px;
                line-height: 26px;

                @include mobile {
                    font-size: 12px;
                    line-height: 1.4em;
                }
            }
        }
    }

    .our-story_content {
        padding-block: 30px;
        
        @include mobile {
            margin-block-start: 40px;
        }

        h2 {
            color: $white;
            font-size: 64px;
            font-family: $font-NeuePlakBlack-Condensed;

            @include mobile {
                font-size: 28px;
            }
        }

        .middle-text {
            margin-inline: auto;
            color: $white;
            margin-block-end: 20px;

            @include desktop {
                width: 56%;
            }

            p {
                color: $white;
                font-size: 16px;
                text-align: center;
                line-height: 1.4em;
            }
        }

        .SlickSlider {
            .pagebuilder-overlay {
                position: relative;
                justify-content: flex-start;
                
                @include desktop {
                    margin-block-end: 80px;
                }

                @include mobile {
                    padding-inline: 15px;
                    margin-block-end: 0;
                    padding-block-end: 10px;
                    align-items: flex-start;
                }
            }

            .pagebuilder-poster-content {
                width: 48%;
                position: static;

                h2 {
                    color: $white;
                    text-align: left;

                    @include desktop {
                        font-size: 70px;
                        
                    }
                }

                h4 {
                    color: $white;
                    font-family: $font-NeuePlakBlack-Condensed;
                    font-weight: 900;
                    line-height: 1em;
                    
                    @include desktop {
                        font-size: 30px;
                    }
                }

                p {
                    color: $white;
                    position: static;

                    @include desktop {
                        font-size: 16px;
                    }

                    span {
                        position: static;

                        img {
                            position: absolute;
                            right: 20px;
                            top: 30px;
                            width: 45% !important;
                            height: auto !important;
                        }
                    }


                }

                >div {
                    position: static;
                }
            }

            .slick-dots {
                display: block !important;

                li {
                    &.slick-active {
                        button {
                            background: #D9D9D9;
                        }
                    }

                    button {
                        background: #575757;
                    }
                }
            }
        }

        .tabs-navigation {
            display: flex !important;
            align-items: center;
            justify-content: center;

            li {
                &.tab-header {
                    background: transparent !important;
                    border: 0 !important;
                    color: $white;

                    .tab-title {
                        color: $white !important;
                        font-family: $font-NeuePlakBlack-Condensed;
                        font-weight: 900;
                        padding: 10px !important;
                        margin-inline: 15px;
                        border-bottom: 1px solid transparent;
                        
                        @include desktop {
                            font-size: 30px !important;
                        }

                        @include mobile {
                            font-size: 20px !important;
                        }

                        span {
                            font-family: inherit;
                        }
                    }

                    &.ui-tabs-active {
                       .tab-title {
                            border-bottom: 1px solid #ED1C24 !important;
                       }
                    }
                }

                &::before {
                    display: none;
                }
            }
        }

        .tabs-content {
            border: 0 !important;
        }
    }

    .store-location {
        h2 {
            font-size: 40px;
            font-family: $font-NeuePlakBlack-Condensed;
            font-weight: 900;
            margin-block-end: 0;
            color: $default-primary-base-color;

            @include mobile {
                font-size: 26px;
                margin-block-start: 10px;
            }
        }
        
        p, a {
            text-decoration: none;
            
            @include desktop {
                font-size: 20px;
            }
        }
    }

    .CmsPage {
        &-Wrapper {
            @include mobile {
                padding-inline-start: 0 !important;
                padding-inline-end: 0 !important;
            }
        }
    }
}
