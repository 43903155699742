/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductsLinked {
    margin-block-end: 90px;

    .slick-list {
        margin: 0 -17px;

        .slick-slide {
            > div {
                padding: 0 17px;
            }
        }
    }
}

.SlickSlider {
    @include desktop {
        padding: 0 40px;
    }
    /* Slider */

    .slick-slider {
        position: relative;
        display: block;
        box-sizing: border-box;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent;
    }

    .slick-list {
        position: relative;
        overflow: hidden;
        display: block;
        margin: 0;
        padding: 0;

        &:focus {
            outline: none;
        }

        &.dragging {
            cursor: pointer;
            cursor: hand;
        }
    }

    .slick-slider .slick-track,
    .slick-slider .slick-list {
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    .slick-track {
        position: relative;
        inset-inline-start: 0;
        inset-block-start: 0;
        display: block;
        margin-inline-start: auto;
        margin-inline-end: auto;

        &::before,
        &::after {
            content: "";
            display: table;
        }

        &::after {
            clear: both;
        }

        .slick-loading & {
            visibility: hidden;
        }
    }

    .slick-slide {
        float: inline-start;
        height: 100%;
        min-height: 1px;

        [dir="rtl"] & {
            float: inline-end;
        }

        img {
            display: block;
        }

        &.slick-loading img {
            display: none;
        }

        &.dragging img {
            pointer-events: none;
        }

        .slick-initialized & {
            display: block;
        }

        .slick-loading & {
            visibility: hidden;
        }

        .slick-vertical & {
            display: block;
            height: auto;
            border: 1px solid transparent;
        }
    }

    .slick-arrow.slick-hidden {
        display: none;
    }

    @charset "UTF-8";

    // Default Variables

    // Slick icon entity codes outputs the following
    // "\2190" outputs ascii character "←"
    // "\2192" outputs ascii character "→"
    // "\2022" outputs ascii character "•"

    $slick-font-path: "./fonts/" !default;
    $slick-font-family: "slick" !default;
    $slick-loader-path: "./" !default;
    $slick-arrow-color: black !default;
    $slick-dot-color: black !default;
    $slick-dot-color-active: $slick-dot-color !default;
    $slick-prev-character: "<" !default;
    $slick-next-character: ">" !default;
    $slick-dot-character: "\2022" !default;
    $slick-dot-size: 6px !default;
    $slick-opacity-default: 1 !default;
    $slick-opacity-on-hover: 1 !default;
    $slick-opacity-not-active: 0 !default;

    @function slick-image-url($url) {
        @if function-exists(image-url) {
            @return image-url($url);
        } 
        
        @else {
            @return url($slick-loader-path + $url);
        }
    }

    @function slick-font-url($url) {
        @if function-exists(font-url) {
            @return font-url($url);
        } 
        
        @else {
            @return url($slick-font-path + $url);
        }
    }

    /* Arrows */

    .slick-prev,
    .slick-next {
        position: absolute;
        display: block;
        height: 28px;
        width: 28px;
        line-height: 0px;
        font-size: 0px;
        cursor: pointer;
        // background: transparent;
        color: transparent;
        inset-block-start: 50%;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        padding: 0;
        border: none;
        outline: none;
        background: url(images/arrow.svg) no-repeat;
        background-position: center;
        border-radius: 50%;
        box-shadow: 0 0 4px 1px rgba(0,0,0, 0.1);

        &:hover,
        &:focus {
            opacity: $slick-opacity-on-hover;
            box-shadow: 0 0 4px 1px rgba(0,0,0, 0.5);
        }
        
        &.slick-disabled {
            opacity: $slick-opacity-not-active;
        }
        
        @include mobile {
            // height: 20px;
            // width: 12px;
            // background-size: 100%;
            inset-block-start: 35%;
            /* stylelint-disable-next-line declaration-no-important */
            display: none !important;
        }
    }

    .slick-prev {
        inset-inline-start: 8px;
        transform: translate(0, -50%) rotate(180deg);
        z-index: 9;
        background-color: $white;
    }

    .slick-next {
        inset-inline-end: 8px;
        background-color: $white;
    }

    /* Dots */

    .slick-dotted.slick-slider {
        margin-block-end: 30px;
    }

    .slick-dots {
        inset-block-end: 0;
        list-style: none;
        text-align: center;
        padding: 0;
        margin: 0;
        width: 100%;
        // display: flex;
        flex-wrap: wrap;
        margin-inline-start: 0;
        /* stylelint-disable-next-line declaration-no-important */
        display: none !important;

        li {
            position: relative;
            display: inline-block;
            padding: 0;
            cursor: pointer;
            flex-grow: 1;
            margin: 0;

            &::before {
                display: none;
            }

            button {
                border: 0;
                // background: transparent;
                display: block;
                outline: none;
                line-height: 0px;
                font-size: 0px;
                color: transparent;
                cursor: pointer;
                background: #D0D0D0;
                margin-inline: 8px;
                border-radius: 100%;
                height: 10px;
                width: 10px;

                &:hover,
                &:focus {
                    outline: none;
                    background: $default-primary-base-color;
                }
            }

            &.slick-active button {
                background: $default-primary-base-color;
            }
        }
    }
}
