/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.GiftCardAction {
    @include desktop {
        margin-block-end: 40px;
    }

    &-Title {
        font-size: 24px;
        color: $default-primary-base-color;
        line-height: 32px;
        padding-inline-end: 50px;
        border-bottom: 1px solid $border-color;
        margin-block-end: 27px;
        padding-block-end: 10px;
        font-weight: 600;
        text-transform: none;

        @include mobile {
            font-size: 16px;
            font-weight: 700;
        }
    }

    input, textarea {
        font-size: 12px;
    }

    &-SubTitle {
        font-size: 18px;
        font-weight: 600;
        margin-block-end: 15px;
        line-height: 1;

        @include mobile {
            font-size: 16px;
        }
    }

    &-PriceRange {
        margin-block-start: 0px;
        font-size: 14px;
        font-weight: 300;
        color: $default-primary-base-color;
        margin-block-end: 25px;
        letter-spacing: 1px;
    }

    &-PriceList {
        
        .FieldGroup {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            padding-inline-start: 0;
            border: 0;
        }

        .Field-RadioLabel {
            padding: 10px 10px 8px;
            border-radius: 4px;
            margin-inline-end: 15px;
            margin-block-end: 15px;
            height: 32px;
            display: flex;
            min-width: 72px;
            font-size: 12px;
            align-items: center;
            &:focus, &:active, &:hover {
                // border-color: $default-primary-base-color;
            }

            @include mobile {
                font-size: 11px;
            }

            .input-control {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                left: 0;
                background-color: transparent;
                border-radius: 4px;

                &:after {
                    display: none;
                }
            }
        }

        .Field {
            margin-block-start: 0;
            [type='radio'] {
                &:checked {
                    & + .input-control {
                        border-width: 2px;
                    }
                }
            }
        }
    }

    &-CustomPrice {
        padding-block-end: 15px;

        .Field {
            margin-block-start: 0;
        }
        input {
            width: 100%;
        }
    }

    &-SendInformation {
        margin-block-start: 15px;

        input {
            width: 100%;
        }

        .Field {
            margin-block-start: 5px;
        }
    }

    .ProductActions {
        &-AddToCartWrapper {
            padding-top: 20px;
            align-items: flex-end;

            .Field {
                margin-block-start: 0;
                margin-inline-end: 10px;
                width: 90px;

                input {
                    width: 100%;
                }
            }
        }
    }
   .Custom-Breadcrumbs {
       .ContentWrapper  {
           padding-inline-start: 0;

           .Breadcrumbs-List {
               padding-block-start: 15px;
           }
       }
   } 

}
