/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable declaration-no-important */

:root {
    --header-logo-width: 194px;
    --header-logo-height: 24px;

    --header-height: 60px;
    --header-nav-height: 60px;
    --header-total-height: calc(
        var(--header-height) + var(--offline-notice-height) + var(--demo-notice-height) + env(safe-area-inset-top) - 32px);


    @include desktop {
        --header-top-menu-height: 39px;
        --header-height: calc(var(--header-nav-height) + var(--header-top-menu-height) + var(--menu-total-height) - 23px);
    }

    @include mobile { 
        --header-total-height: calc(
            var(--header-height) + var(--offline-notice-height) + var(--demo-notice-height) + env(safe-area-inset-top) + 40px);
    }
}

.d-block {
    display: block;

    img {
        display: block;
    }
}

.Header {
    background-color: $defult-primary-background-color;
    border-bottom: 0;

    &-Wrapper {
        @include mobile {
            // margin-block-end: 100px;
        }

        .Header { 
            transition: all 0.1s ease-in;
        }

        // &_isStickHeader {
        //     .Header {
        //         @include mobile {
        //             inset-block-start: -40px;
        //             // transition: all 0.3s ease-in;
        //         }
        //     }
        // }
    }

    &-CountryDropdown {
        color: $white;
        font-size: 12px;
        font-weight: 600;
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
            width: 18px;
            margin-inline-end: 8px;
        }

        span {
            color: $white;
        }

        button {
            display: flex;
        }
    }
    
    &-Top {
        border-bottom: 1px solid $white;
        max-width: 100%;
        padding-block-end: 10px;
        margin-block-end: 2px;

        @include mobile {
            margin-block-end: 0;
        }
    }

    &-TopMenu {
        height: auto;
        padding-block-start: 10px;
        justify-content: flex-end;
        padding-inline: 10px;

        .Header-Welcome {
            &::after {
                content: '';
                position: relative;
                width: 0;
                height: 0;
                border-top: 6px solid $white;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                inset-block-start: 0;
                inset-inline-end: 0px;
                display: inline-block;
                margin-inline-start: 6px;
            }

            &_isexpandToggle_UP {
                &::after {
                    transform: rotate(-180deg);
                }
            }

            @include desktop {
                display: block;
            }
        }

        .Header-CountryDropdown {
            &::after {
                content: '';
                position: relative;
                width: 0;
                height: 0;
                border-top: 6px solid $white;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                inset-block-start: 0;
                inset-inline-end: 0px;
                display: inline-block;
                margin-inline-start: 6px;
            }

            &_isexpandToggle_UP {
                &::after {
                    transform: rotate(-180deg);
                }
            }

            @include desktop {
                display: flex;
            }
        }
    }

    &-News {
        color: $white;
        margin: 0 auto;
        font-size: 12px;
        font-weight: 600;
        
        @include desktop {
            position: absolute;
            inset-inline-start: 50%;
            transform: translateX(-50%);
        }

        button {
            color: $white;
            font-weight: 600;
            font-size: 12px;
            cursor: pointer;
        }

        .promo-code {
            p {
                color: $white;
                font-weight: 600;
                font-size: 12px;
                margin-block-end: 0;
            }
        }
    }

    &-Switcher {
        button {
            span {
                color: $white;
            }
        }
    }

    &-Welcome {
        color: $white;
        cursor: pointer;
        margin-inline-end: 0;
        font-size: 12px;
    }

    &-Auth {
        display: flex;
        font-family: $font-NeuePlak;
        font-weight: 600;

        button {
            cursor: pointer;

            span {
                color: $white;
                font-size: 12px;
                font-weight: 600;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .Header {
            &-login {
                margin-inline-start: 8px;
                padding-inline-start: 10px;

                &::before {
                    content: "|";
                    position: absolute;
                    inset-inline-start: 0;
                    inset-block-start: 0;
                    color: $white;
                }
            }
        }
    }

    &-WishList {
        margin-inline-start: 35px;

        .Wishlist {
            position: relative;
            color: $drakGray-color;

            svg {
                height: 15px;
                width: 15px;
                stroke: $drakGray-color;
                fill: transparent;
            }

            .count {
                position: absolute;
                inset-block-start: -10px;
                inset-inline-end: -8px;
                background-color: red;
                color: $white;
                font-size: 9px;
                border-radius: 50%;
                width: 16px;
                height: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                line-height: 1;
                padding-block-start: 2px;
            }
        }
    }

    &-MinicartItemCount {
        background-color: red;
        color: $white;
        font-size: 9px;
        border-radius: 50%;
        inset-block-start: 10px;
    }

    &-Nav {
        @media only screen and (min-width: 1600px) {
            padding-inline-start: 0;
            padding-inline-end: 0;
        }      

        @include mobile {
            justify-content: space-between;
            padding-inline-start: 20px;
            padding-inline-end: 20px;
        }

        .Header-Welcome {
            @include desktop {
                display: none;
            }
        }

        .Header-LogoWrapper {
            @include mobile {
                width: auto;
                margin: 0;
                order: 3;
            }
        }

        .Header-OpenMenuBtn {
            @include mobile {
                order: 1;
                display: flex;
            }
        }

        .Header-AccountBtn {
            @include mobile {
                order: 2;
            }
        }

        .Header-MinicartButtonWrapper {
            @include desktop {
                padding-inline-start: 35px;
                height: 100%;
                display: flex;
                align-items: center;
            }

            @include mobile {
                order: 5;
                display: flex;
                align-items: center;
            }
        }

        .Header-SearchBtn {
            @include mobile {
                order: 4;
                display: flex;
                align-items: center;
            }

            .SearchIcon {
                @include mobile {
                    fill: $white;
                }
            }
        }

        .SearchField {
            &-CloseIcon {
                svg {
                    width: 12px;
                    height: 12px;
                    inset-block-start: 5px;
                }
            }
        }
    }
    
    &-Button {
        &_type {
            &_minicart {
                @include desktop {
                    height: 100%;
                    width: auto;
                    margin-inline-start: 0;
                }
                
                svg {
                    @include desktop {
                        margin-block-start: -5px;
                    }
                }

                .CartItem {
                    &-ProductActions {
                        .saveForLater {
                            display: none;
                        }
                    }
                    
                    &-Link {
                        &.edit {
                            display: none;
                        }
                    }
                }
            }

            &_share {
                display: none;
            }
        }
    }

    &_isCheckout {
        .Header {
            &-Top {
                display: none;
            }

            &-Nav {
                justify-content: center;
            }

            &-LogoWrapper {
                width: auto;
                margin-inline-start: auto;

                @include mobile {
                    margin-inline-end: auto;
                }
            }

            &-Button_type_minicart {
                margin-inline-start: auto;
            }

            &-AccountBtn, &-HamburgerMenuWrapper, &-SearchBtn {
                @include mobile {
                    display: none;
                }
            }
        }
    }
}

.HomePage {
    .banner-content {
        inset-inline-end: 0;
        text-align: start !important;
        
        @include desktop {
            inset-block-start: 50%;
            position: absolute;
            width: 480px;
            inset-inline-end: 40px;
            transform: translateY(-50%);
        }

        @media(max-width: 1400px) {
            width: 460px;
            inset-block-end: 0;
        }

        @media(max-width: 1300px) {
            width: 420px;
        }

        @media(max-width: 900px) {
            width: 100%;
        }

        h1 {
            color: $black;

            @include mobile {
                padding-inline-start: 0;
                padding-block-start: 10px;
            }
        }

        h2 {
            color: $black;

            @include mobile {
                padding-block-start: 25px;
            }
        }

        h1, h2 {
            font-size: 64px;
            margin-block-end: 10px;
            font-family: $font-NeuePlakBlack-Condensed;
            letter-spacing: 2px;
            line-height: 1em;
            
            @media(max-width: 1100px) {
                font-size: 45px;
            }

            @include mobile {
                font-size: 40px;
                text-align: center;
                color: $default-primary-base-color;
            }

            @media (max-width: 370px) {
                font-size: 28px;
            }
        }

        .name {
            @include desktop {
                color: $black;
                position: absolute;
                inset-inline-end: 15px;
                inset-block-start: -40%;
                font-size: 11px;
            }

            @media(max-width: 1400px) {
                inset-block-start: -45%;
            }

            @include mobile {
                color: $black;
                position: absolute;
                inset-inline-end: 0;
                inset-block-start: -86%;
                font-size: 8px;
            }
        }

        .Top {
            margin-block-end: 10px;

            @include mobile {
                margin-block-start: 10px;
                margin-block-end: 0;
                padding: 0 !important;
            }
        }

        p {
            line-height: 20px;
            color: $black;
            font-weight: 600;
            font-size: 14px;
            margin-block-end: 20px;

            @include mobile {
                color: $default-primary-base-color;
                text-align: center;
                margin-block-end: 30px;
                font-weight: 400;
                padding-inline-start: 32px;
                padding-inline-end: 32px;
            }

            @media (max-width: 400px) {
                padding-inline-start: 10px;
                padding-inline-end: 10px;
            }

            &.big {
                font-weight: 700;
                font-size: 18px;
            }
        }

        .button-primary {
            width: 212px;
            background-color: $black;
            color: $white;
            font-size: 12px;
            border-radius: 4px;
            text-transform: capitalize;
            border: 0;
            padding: 16px 20px;
            height: auto;
            display: block;
            text-align: center;
            line-height: 16px;
            margin-block-start: 10px;

            &:hover {
                color: $btn-primary-color;
            }

            @include mobile {
                color: $white;
                margin-inline-start: 0;
                background-color: $default-primary-base-color;
                width: 100%;
            }
        }
    }

    .CmsPage {
        .home-banner-section {
            position: relative;
        }

        .Home {
            &-banner {
                @include mobile {
                    margin-inline-end: -14px;
                    margin-inline-start: -14px;
                    margin-block-start: 2px;
                }

                .pagebuilder {
                    &-banner {
                        &-wrapper {
                            height: 720px;
                            background-position: center;

                            @media (max-width: 500px) {
                                height: 400px;
                            }
                        }
                    }
                }

                img {
                    width: 100%;
                }
            }
        }
    }

    .first-row {
        margin-block-end: 30px;

        .banner-content {
            inset-inline-start: 45px;
            inset-block-end: 45px;
            width: 350px;
            transform: translate(0, 0);
            inset-block-start: inherit;
            text-align: start !important;

            @media(max-width: 1400px) {
                width: 350px;
            }
    
            @media(max-width: 1300px) {
                width: 350px;
            }

            @include mobile {
                inset-inline-start: 0;
                inset-block-end: 0;
                inset-block-start: inherit;
                width: 100%;
                transform: unset;
                text-align: center !important;
            }

            h2, h1 {
                @include desktop {
                    color: $white;
                    margin-block-end: 25px;
                }

                @include mobile {
                    margin-block-start: 0;
                    color: $black;
                }
            }
     
            p {
                margin-block-end: 25px;

                &.Top {
                    margin-block-end: 10px;

                    @include mobile {
                        margin-block-end: 0;
                    }
                }

                &.mb_d-none {
                    @include mobile {
                        display: none;
                    }
                }

                @include desktop {
                    color: $white;
                    padding-inline-end: 0;
                    padding-inline-start: 0;
                }

                @include mobile {
                    color: $black;
                }
            }

            .button-primary {
                @include desktop {
                    background-color: $white;
                    color: $black;
                    margin-inline-start: 0;
                    margin: 0 0 !important;

                    &:hover {
                        color: $btn-primary-color;
                    }
                }

                @include mobile {
                    // display: block;
                    // max-width: 212px;
                    width: 100%;
                    color: $white;
                    background-color: $black;
                    margin: 0 auto !important;
                }
            }

            .name {    
                @include mobile {
                    color: $white;
                    font-weight: bold;
                    position: absolute;
                    inset-inline-end: 0;
                    inset-block-start: -30%;
                    font-size: 8px;
                    line-height: 12px;
                    text-align: end;
                    margin: 0;
                    padding: 0;
                }
            }
        }

        .top-content {
            position: absolute;
            inset-block-start: 20px;
            inset-inline-end: 20px;

            @include mobile {
                inset-block-start: 7px;
                inset-inline-end: 7px;
            }

            h5 {
                text-align: end;
                color: $white;
                font-size: 12px;

                @include mobile {
                    font-size: 9px;
                }
            }
        }

        .video-button {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 99;
            cursor: pointer;
            
            div {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 99;

                a {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: 99;

                    span {
                        display: none;
                    }
                }
            }
        }

        .name {
            @include desktop {
                position: absolute;
                inset-inline-end: 1%;
                inset-block-end: 3%;
                inset-block-start: inherit;
            }

            @media(max-width: 1400px) {
                inset-block-start: inherit;
            }

            p {
                color: $black;
                font-size: 11px;
                line-height: 10px;
                margin-block-end: 0px;
                font-weight: bold;
            }
        }

        .multi-cta {
            @include desktop {
                display: flex;
                justify-content: space-around;
                position: absolute;
                inset-block-end: 20px;
                width: 100%;
            }

            .button-primary {
                width: 212px;
                background-color: $white;
                color: $black;
                font-size: 12px;
                border-radius: 4px;
                text-transform: capitalize;
                border: 0;
                padding: 16px 20px;
                height: auto;
                display: block;
                text-align: center;
                line-height: 16px;
                margin-block-start: 10px;
    
                &:hover {
                    color: $btn-primary-color;
                }
    
                @include mobile {
                    color: $white;
                    margin-inline-start: 0;
                    background-color: $default-primary-base-color;
                    width: 100%;
                }
            }
        }
    }

    .elite-row {
        margin-block-end: 30px;
    
        .banner-content {
            inset-inline-start: 45px;
            inset-block-end: 45px;
            width: 270px;
            transform: translate(0, 0);
            inset-block-start: inherit;
            text-align: start !important;
    
            @media(max-width: 1400px) {
                width: 270px;
            }
    
            @media(max-width: 1300px) {
                width: 270px;
            }
    
            @include mobile {
                inset-inline-start: 0;
                inset-block-end: 0;
                inset-block-start: inherit;
                width: 100%;
                transform: unset;
                text-align: center !important;
            }
    
            h2, h1 {
                @include desktop {
                    color: $black;
                    margin-block-end: 25px;
                }
    
                @include mobile {
                    margin-block-start: 0;
                    color: $black;
                }
            }
        
            p {
                margin-block-end: 25px;
    
                &.Top {
                    margin-block-end: 10px;
    
                    @include mobile {
                        margin-block-end: 0;
                    }
                }
    
                &.mb_d-none {
                    @include mobile {
                        display: none;
                    }
                }
    
                @include desktop {
                    color: $black;
                    padding-inline-end: 0;
                    padding-inline-start: 0;
                }
    
                @include mobile {
                    color: $black;
                }
            }
    
            .button-primary {
                @include desktop {
                    background-color: $black;
                    color: $white;
                    margin-inline-start: 0;
                    margin: 0 0 !important;
    
                    &:hover {
                        color: $btn-primary-color;
                    }
                }
    
                @include mobile {
                    // display: block;
                    // max-width: 212px;
                    width: 100%;
                    color: $white;
                    background-color: $black;
                    margin: 0 auto !important;
                }
            }
    
            .name {    
                @include mobile {
                    color: $white;
                    font-weight: bold;
                    position: absolute;
                    inset-inline-end: 0;
                    inset-block-start: -30%;
                    font-size: 8px;
                    line-height: 12px;
                    text-align: end;
                    margin: 0;
                    padding: 0;
                }
            }
        }
    
        .top-content {
            position: absolute;
            inset-block-start: 20px;
            inset-inline-end: 20px;
    
            @include mobile {
                inset-block-start: 7px;
                inset-inline-end: 7px;
            }
    
            h5 {
                text-align: end;
                color: $white;
                font-size: 12px;
    
                @include mobile {
                    font-size: 9px;
                }
            }
        }
    
        .video-button {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 99;
            cursor: pointer;
            
            div {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 99;
    
                a {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: 99;
    
                    span {
                        display: none;
                    }
                }
            }
        }
    
        .name {
            @include desktop {
                position: absolute;
                inset-inline-end: 1%;
                inset-block-end: 3%;
                inset-block-start: inherit;
            }
    
            @media(max-width: 1400px) {
                inset-block-start: inherit;
            }
    
            p {
                color: $black;
                font-size: 11px;
                line-height: 10px;
                margin-block-end: 0px;
                font-weight: bold;
            }
        }
    
        .multi-cta {
            @include desktop {
                display: flex;
                justify-content: space-around;
                position: absolute;
                inset-block-end: 20px;
                width: 100%;
            }
    
            .button-primary {
                width: 212px;
                background-color: $black;
                color: $white;
                font-size: 12px;
                border-radius: 4px;
                text-transform: capitalize;
                border: 0;
                padding: 16px 20px;
                height: auto;
                display: block;
                text-align: center;
                line-height: 16px;
                margin-block-start: 10px;
    
                &:hover {
                    color: $btn-primary-color;
                }
    
                @include mobile {
                    color: $white;
                    margin-inline-start: 0;
                    background-color: $default-primary-base-color;
                    width: 100%;
                }
            }
        }
    }


    .zidd-row {
        margin-block-end: 30px;
    
        .banner-content {
            inset-inline-start: 45px;
            inset-block-end: 45px;
            width: 270px;
            transform: translate(0, 0);
            inset-block-start: inherit;
            text-align: start !important;
    
            @media(max-width: 1400px) {
                width: 270px;
            }
    
            @media(max-width: 1300px) {
                width: 270px;
            }
    
            @include mobile {
                inset-inline-start: 0;
                inset-block-end: 0;
                inset-block-start: inherit;
                width: 100%;
                transform: unset;
                text-align: center !important;
            }
    
            h2, h1 {
                @include desktop {
                    color: $black;
                    margin-block-end: 25px;
                }
    
                @include mobile {
                    margin-block-start: 0;
                    color: $black;
                }
            }
        
            p {
                margin-block-end: 25px;
    
                &.Top {
                    margin-block-end: 10px;
    
                    @include mobile {
                        margin-block-end: 0;
                    }
                }
    
                &.mb_d-none {
                    @include mobile {
                        display: none;
                    }
                }
    
                @include desktop {
                    color: $black;
                    padding-inline-end: 0;
                    padding-inline-start: 0;
                }
    
                @include mobile {
                    color: $black;
                }
            }
    
            .button-primary {
                @include desktop {
                    background-color: $black;
                    color: $white;
                    margin-inline-start: 0;
                    margin: 0 0 !important;
    
                    &:hover {
                        color: $btn-primary-color;
                    }
                }
    
                @include mobile {
                    // display: block;
                    // max-width: 212px;
                    width: 100%;
                    color: $white;
                    background-color: $black;
                    margin: 0 auto !important;
                }
            }
    
            .name {    
                @include mobile {
                    color: $white;
                    font-weight: bold;
                    position: absolute;
                    inset-inline-end: 0;
                    inset-block-start: -30%;
                    font-size: 8px;
                    line-height: 12px;
                    text-align: end;
                    margin: 0;
                    padding: 0;
                }
            }
        }
    
        .top-content {
            position: absolute;
            inset-block-start: 20px;
            inset-inline-end: 20px;
    
            @include mobile {
                inset-block-start: 7px;
                inset-inline-end: 7px;
            }
    
            h5 {
                text-align: end;
                color: $white;
                font-size: 12px;
    
                @include mobile {
                    font-size: 9px;
                }
            }
        }
    
        .video-button {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 99;
            cursor: pointer;
            
            div {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 99;
    
                a {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: 99;
    
                    span {
                        display: none;
                    }
                }
            }
        }
    
        .name {
            @include desktop {
                position: absolute;
                inset-inline-end: 1%;
                inset-block-end: 3%;
                inset-block-start: inherit;
            }
    
            @media(max-width: 1400px) {
                inset-block-start: inherit;
            }
    
            p {
                color: $black;
                font-size: 11px;
                line-height: 10px;
                margin-block-end: 0px;
                font-weight: bold;
            }
        }
    
        .multi-cta {
            @include desktop {
                position: absolute;
                inset-block-end: 50px;
                inset-inline-start: 8%;
                width: 195px;
            }
    
            .button-primary {
                width: 100%;
                background-color: $white;
                color: $black;
                font-size: 12px;
                border-radius: 4px;
                text-transform: capitalize;
                border: 0;
                padding: 16px 20px;
                height: auto;
                display: block;
                text-align: center;
                line-height: 16px;
                margin-block-start: 20px;
    
                &:hover {
                    color: $btn-primary-color;
                }
    
                @include mobile {
                    color: $white;
                    margin-inline-start: 0;
                    background-color: $default-primary-base-color;
                    width: 100%;
                }
            }

            @media (max-width: 1150px) {
                inset-block-end: 30px;
                width: 155px;

                .button-primary {
                    margin-block-start: 10px;
                }
            }

            @include mobile {
                inset-block-end: 0;
                width: 100%;
            }
        }
    }

    .second-row {
        margin-inline-end: auto;
        margin-inline-start: auto;
        
        @include desktop {
            padding-block-end: 40px;
            width: 85%;
            padding-block-start: 20px;
        }

        @include mobile {
            margin-block-start: 15px;
        }

        h3 {
            font-size: 32px;
            font-weight: 700;
            color: $black;
            margin-block-start: 0px;
            margin-block-end: 0px;

            @include mobile {
                font-size: 16px;
                margin-block-start: 0px;
            }
        }

        .pagebuilder-column {
            padding: 15px;

            @include mobile {
                padding: 0 15px;
            }

            &.half-width {
                @include mobile {
                    max-width: 50%;
                    padding: 10px 10px 15px 10px;
                }
            }  
        }

        figure {
            img {
                width: 100%;
            }
         
            figcaption {
                font-size: 14px;
                font-weight: 600;

                @include mobile {
                    font-size: 12px;
                    text-decoration: underline;
                }
            }

            &:hover {
                figcaption {
                    text-decoration: underline;
                }
            }
        }
    }


    .zidd-banner-row {
        margin-inline-end: auto;
        margin-inline-start: auto;
        
        @include desktop {
            padding-block-end: 40px;
            width: 100%;
            padding-block-start: 20px;
        }

        @include mobile {
            margin-block-start: 15px;
        }

        h3 {
            font-size: 32px;
            font-weight: 700;
            color: $black;
            margin-block-start: 0px;
            margin-block-end: 0px;

            @include mobile {
                font-size: 16px;
                margin-block-start: 0px;
            }
        }

        .pagebuilder-column {
            padding: 15px;

            @include mobile {
                padding: 0 15px;
            }

            &.half-width {
                @include mobile {
                    max-width: 50%;
                    padding: 10px 10px 15px 10px;
                }
            }  
        }

        figure {
            img {
                width: 100%;
            }
         
            figcaption {
                font-size: 14px;
                font-weight: 600;

                @include mobile {
                    font-size: 12px;
                    text-decoration: underline;
                }
            }

            &:hover {
                figcaption {
                    text-decoration: underline;
                }
            }
        }
    }

    .third-row {
        margin-block-end: 30px;
        margin-block-start: 30px;

        .pagebuilder-column-group {
            align-items: flex-end;

            .pagebuilder-column {
                align-self: auto ;
                
                @include mobile {
                    max-width: 50%;
                }
            }
        }

        .pagebuilder {
            &-banner {
                &-wrapper {
                    height: 672px;
                    background-position: center;

                    @media (max-width: 500px) {
                        height: 300px;
                        margin-block-end: 40px;
                    }
                }
            }
        }

        .banner-content {
            inset-inline-start: 45px;
            inset-inline-end: inherit;
            inset-block-start: inherit;
            // inset-block-end: 110px;
            inset-block-end: 50%;
            transform: translateY(50%);
            width: 400px;

            @media(max-width: 1400px) {
                width: 380px;
                inset-inline-start: 45px;
                inset-inline-end: inherit;
                inset-block-start: inherit;
                // inset-block-end: 110px;
                inset-block-end: 50%;
                transform: translateY(50%);
            }
    
            @media(max-width: 1300px) {
                width: 350px;
                inset-inline-start: 45px;
                inset-inline-end: inherit;
                inset-block-start: inherit;
                // inset-block-end: 110px;
                inset-block-end: 50%;
                transform: translateY(50%);
            }

            @include mobile {
                inset-inline-start: 0;
                inset-block-end: 0;
                inset-block-start: inherit;
                width: 100%;
                transform: unset;
            }

            h1 {
                @include desktop {
                    color: $black;
                }

                @include mobile {
                    margin-block-start: 0;
                }
            }

            h2 {
                @include desktop {
                    color: $black;
                }

                @include mobile {
                    margin-block-start: 0;
                }
            }
     
            p {
                // margin-block-end: 25px;

                @include desktop {
                    color: $black;
                    padding-inline-end: 0;
                    padding-inline-start: 0;
                }
            }

            .button-primary {
                @include desktop {
                    background-color: $black;
                    color: $white;
                    margin-inline-start: 0;

                    &:hover {
                        color: $btn-primary-color;
                    }
                }

                @include mobile {
                    display: block;
                    width: 100%;
                    margin: 0 auto;
                }
            }
        }

        .top-content {
            position: absolute;
            inset-inline-end: 20px;
            inset-block-start: 20px;

            @include mobile {
                inset-inline-start: 0px;
                inset-inline-end: inherit;
                inset-block-start: 15px;
            }

            h5 {
                text-align: start;
                color: $black;
                font-size: 12px;

                @include mobile {
                    color: $white;
                    font-size: 9px;
                }
            }
        }
    }




    .new-row {
        margin-block-end: 30px;
        margin-block-start: 30px;

        .pagebuilder-column-group {
            align-items: flex-end;

            .pagebuilder-column {
                align-self: auto ;
                
                @include mobile {
                    max-width: 50%;
                }
            }
        }

        .pagebuilder {
            &-banner {
                &-wrapper {
                    height: 672px;
                    background-position: center;

                    @media (max-width: 500px) {
                        height: 300px;
                        margin-block-end: 40px;
                    }
                }
            }
        }

        .banner-content {
            // width: 430px;
            width: 500px;
            inset-inline-start: inherit;
            inset-inline-end: inherit;
            inset-block-start: inherit;
            // inset-block-end: 110px;
            inset-block-end: inherit;
            transform: inherit;
            position: inherit;
            margin: 20px auto;

            @include mobile {
                inset-inline-start: 0;
                inset-block-end: 0;
                inset-block-start: inherit;
                width: 100%;
                transform: unset;
            }

            h1 {
                @include desktop {
                    color: $black;
                    text-align: center;
                }

                @include mobile {
                    margin-block-start: 0;
                }
            }

            h2 {
                @include desktop {
                    color: $black;
                    text-align: center;
                }

                @include mobile {
                    margin-block-start: 0;
                }
            }
     
            p {
                // margin-block-end: 25px;

                @include desktop {
                    color: $black;
                    padding-inline-end: 0;
                    padding-inline-start: 0;
                    text-align: center;
                }
            }

            .button-primary {
                @include desktop {
                    background-color: $black;
                    color: $white;
                    margin-inline-start: 0;
                    margin: 0 auto !important;

                    &:hover {
                        color: $btn-primary-color;
                    }
                }

                @include mobile {
                    display: block;
                    width: 100%;
                    margin: 0 auto;
                }
            }
        }

        .top-content {
            position: absolute;
            inset-inline-end: 20px;
            inset-block-start: 20px;

            @include mobile {
                inset-inline-start: 0px;
                inset-inline-end: inherit;
                inset-block-start: 15px;
            }

            h5 {
                text-align: start;
                color: $black;
                font-size: 12px;

                @include mobile {
                    color: $white;
                    font-size: 9px;
                }
            }
        }
    }



    .video-row {
        margin-block-end: 30px;
        margin-block-start: 30px;

        .pagebuilder-video-container {
            padding-block-start: 33% !important;

            @include mobile {
                padding-block-start: 0% !important;
            }
        }

        .pagebuilder-column-group {
            align-items: flex-end;

            .pagebuilder-column {
                align-self: auto ;
                
                @include mobile {
                    max-width: 50%;
                }
            }
        }

        .pagebuilder {
            &-banner {
                &-wrapper {
                    height: 672px;
                    background-position: center;

                    @media (max-width: 500px) {
                        height: 300px;
                        margin-block-end: 40px;
                    }
                }
            }
        }

        .banner-content {
            width: 700px;
            inset-inline-start: inherit;
            inset-inline-end: inherit;
            inset-block-start: inherit;
            inset-block-end: inherit;
            transform: inherit;
            position: inherit;
            margin: 20px auto;

            @include mobile {
                inset-inline-start: 0;
                inset-block-end: 0;
                inset-block-start: inherit;
                width: 100%;
                transform: unset;
            }

            h1 {
                @include desktop {
                    color: $black;
                    text-align: center;
                }

                @include mobile {
                    margin-block-start: 0;
                }
            }

            h2 {
                @include desktop {
                    color: $black;
                    text-align: center;
                }

                @include mobile {
                    margin-block-start: 0;
                }
            }
     
            p {
                // margin-block-end: 25px;

                @include desktop {
                    color: $black;
                    padding-inline-end: 0;
                    padding-inline-start: 0;
                    text-align: center;
                }
            }

            .Top {
                span {
                    vertical-align: super;
                    font-size: 10px;
                }
            }

            .button-wrapper {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                gap: 20px;
            }

            .button-primary {
                @include desktop {
                    background-color: $black;
                    color: $white;
                    margin-inline-start: 0;
                    margin-block-start: 0;
                    width: 160px;

                    &:hover {
                        color: $btn-primary-color;
                    }
                }

                @include mobile {
                    display: block;
                    width: 100%;
                    margin: 0 auto;
                }
            }
        }

        .top-content {
            position: absolute;
            inset-inline-end: 20px;
            inset-block-start: 20px;

            @include mobile {
                inset-inline-start: 0px;
                inset-inline-end: inherit;
                inset-block-start: 15px;
            }

            h5 {
                text-align: start;
                color: $black;
                font-size: 12px;

                @include mobile {
                    color: $white;
                    font-size: 9px;
                }
            }
        }
    }


    .video-row-new {
        margin-block-end: 30px;
        margin-block-start: 30px;

        .pagebuilder-video-container {
            padding-block-start: 33% !important;

            @include mobile {
                padding-block-start: 0% !important;
            }
        }

        .pagebuilder-column-group {
            align-items: flex-end;

            .pagebuilder-column {
                align-self: auto ;
                
                @include mobile {
                    max-width: 50%;
                }
            }
        }

        .pagebuilder {
            &-banner {
                &-wrapper {
                    height: 672px;
                    background-position: center;

                    @media (max-width: 500px) {
                        height: 300px;
                        margin-block-end: 40px;
                    }
                }
            }
        }

        .banner-content {
            width: 100%;
            inset-inline-start: inherit;
            inset-inline-end: inherit;
            inset-block-start: inherit;
            inset-block-end: inherit;
            transform: inherit;
            position: inherit;
            margin: 20px auto;

            @include mobile {
                inset-inline-start: 0;
                inset-block-end: 0;
                inset-block-start: inherit;
                width: 100%;
                transform: unset;
            }

            h1 {
                @include desktop {
                    color: $black;
                    text-align: center;
                }

                @include mobile {
                    margin-block-start: 0;
                }
            }

            h2 {
                @include desktop {
                    color: $black;
                    text-align: center;
                }

                @include mobile {
                    margin-block-start: 0;
                }
            }
     
            p {
                // margin-block-end: 25px;

                @include desktop {
                    color: $black;
                    padding-inline-end: 0;
                    padding-inline-start: 0;
                    text-align: center;
                    width: 700px;
                    margin: 0 auto 20px;
                }
            }

            .Top {
                span {
                    vertical-align: super;
                    font-size: 10px;
                }
            }

            .button-wrapper {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                gap: 20px;
            }

            .button-primary {
                @include desktop {
                    background-color: $black;
                    color: $white;
                    margin-inline-start: 0;
                    margin-block-start: 0;
                    width: 160px;

                    &:hover {
                        color: $btn-primary-color;
                    }
                }

                @include mobile {
                    display: block;
                    width: 100%;
                    margin: 0 auto;
                }
            }
        }

        .top-content {
            position: absolute;
            inset-inline-end: 20px;
            inset-block-start: 20px;

            @include mobile {
                inset-inline-start: 0px;
                inset-inline-end: inherit;
                inset-block-start: 15px;
            }

            h5 {
                text-align: start;
                color: $black;
                font-size: 12px;

                @include mobile {
                    color: $white;
                    font-size: 9px;
                }
            }
        }
    }




    .unstoppable-row {
        margin-block-end: 30px;
        margin-block-start: 30px;

        .pagebuilder-video-container {
            padding-block-start: 33% !important;

            @include mobile {
                padding-block-start: 0% !important;
            }
        }

        .pagebuilder-column-group {
            align-items: flex-end;

            .pagebuilder-column {
                align-self: auto ;
                
                @include mobile {
                    max-width: 50%;
                }
            }
        }

        .pagebuilder {
            &-banner {
                &-wrapper {
                    height: 672px;
                    background-position: center;

                    @media (max-width: 500px) {
                        height: 300px;
                        margin-block-end: 40px;
                    }
                }
            }
        }

        .banner-content {
            width: 100%;
            inset-inline-start: inherit;
            inset-inline-end: inherit;
            inset-block-start: inherit;
            inset-block-end: inherit;
            transform: inherit;
            position: inherit;
            margin: 20px auto;

            @include desktop {
                position: absolute;
                inset-inline-start: 60%;
                inset-block-start: 50%;
                width: auto;
                text-align: start;
                transform: translateY(-50%);
            }

            @include mobile {
                inset-inline-start: 0;
                inset-block-end: 0;
                inset-block-start: inherit;
                width: 100%;
                transform: unset;
            }

            h1 {
                @include desktop {
                    color: $black;
                    text-align: start;
                    font-size: 49px;
                    width: 380px;
                }

                @include mobile {
                    margin-block-start: 0;
                }
            }

            h2 {
                @include desktop {
                    color: $black;
                    text-align: start;
                }

                @include mobile {
                    margin-block-start: 0;
                }
            }
     
            p {
                // margin-block-end: 25px;

                @include desktop {
                    color: $black;
                    padding-inline-end: 0;
                    padding-inline-start: 0;
                    text-align: start;
                    width: 268px;
                    font-size: 16px;
                }
            }

            .Top {
                span {
                    vertical-align: super;
                    font-size: 10px;
                }
            }

            .button-wrapper {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                gap: 20px;

                @include desktop {
                    justify-content: start;
                }
            }

            .button-primary {
                @include desktop {
                    background-color: $black;
                    color: $white;
                    margin-inline-start: 0;
                    margin-block-start: 0;
                    width: 160px;

                    &:hover {
                        color: $btn-primary-color;
                    }
                }

                @include mobile {
                    display: block;
                    width: 100%;
                    margin: 0 auto;
                }
            }
        }

        .top-content {
            position: absolute;
            inset-inline-end: 20px;
            inset-block-start: 20px;

            @include mobile {
                inset-inline-start: 0px;
                inset-inline-end: inherit;
                inset-block-start: 15px;
            }

            h5 {
                text-align: start;
                color: $black;
                font-size: 12px;

                @include mobile {
                    color: $white;
                    font-size: 9px;
                }
            }
        }
    }


    .fourth-row {
        margin-block-end: 30px;
        margin-block-start: 30px;

        .pagebuilder-column-group {
            align-items: flex-end;

            .pagebuilder-column {
                align-self: auto ;
                
                @include mobile {
                    max-width: 50%;
                }
            }
        }

        .button-primary {
            background-color: $black;
            color: $white;
            margin-inline-start: 0;

            &:hover {
                color: $btn-primary-color;
            }
        }

        .pagebuilder {
            &-banner {
                &-wrapper {
                    height: 672px;
                    background-position: center;

                    @media (max-width: 500px) {
                        height: 300px;
                        margin-block-end: 40px;
                    }
                }
            }
        }

        .banner-content {
            inset-inline-start: 45px;
            text-align: start !important;
            
            @include desktop {
                inset-block-end: 70px;
                inset-block-start: inherit;
                position: absolute;
                width: 370px;
                transform: inherit;
            }
    
            @media(max-width: 1400px) {
                width: 360px;
                inset-block-end: 50px;
            }
    
            @media(max-width: 1300px) {
                width: 330px;
            }
    
            @media(max-width: 900px) {
                width: 100%;
                inset-inline-start: 0;
                inset-block-end: 0;
            }
    
            h1 {
                color: $white;
    
                @include mobile {
                    padding-inline-start: 0;
                    justify-content: center;
                }
            }
    
            h2 {
                color: $white;
    
                @include mobile {
                    padding-block-start: 25px;
                }
            }
    
            h1, h2 {
                font-size: 64px;
                margin-block-end: 10px;
                font-family: $font-NeuePlakBlack-Condensed;
                letter-spacing: 2px;
                line-height: 1em;
                
                @media(max-width: 1100px) {
                    font-size: 45px;
                }
    
                @include mobile {
                    font-size: 40px;
                    text-align: center;
                    color: $default-primary-base-color;
                }
    
                @media (max-width: 370px) {
                    font-size: 28px;
                }
            }
    
            .name {
                @include desktop {
                    color: $black;
                    position: absolute;
                    inset-inline-end: 15px;
                    inset-block-start: -40%;
                    font-size: 11px;
                }
    
                @media(max-width: 1400px) {
                    inset-block-start: -45%;
                }
    
                @include mobile {
                    color: $black;
                    position: absolute;
                    inset-inline-end: 0;
                    inset-block-start: -86%;
                    font-size: 8px;
                }
            }
    
            .Top {
                margin-block-end: 10px;
    
                @include mobile {
                    margin-block-start: 10px;
                    margin-block-end: 0;
                    padding: 0 !important;
                }
            }
    
            p {
                line-height: 20px;
                color: $white;
                font-weight: 600;
                font-size: 14px;
                margin-block-end: 25px;
                padding-inline-end: 40px;
    
                @include mobile {
                    color: $default-primary-base-color;
                    text-align: center;
                    margin-block-end: 30px;
                    font-weight: 400;
                    padding-inline-start: 32px;
                    padding-inline-end: 32px;
                }
            }
    
            .button-primary {
                width: 212px;
                background-color: $white;
                color: $black;
                font-size: 12px;
                border-radius: 4px;
                text-transform: capitalize;
                border: 0;
                padding: 16px 20px;
                height: auto;
                display: block;
                text-align: center;
                line-height: 16px;
                margin-block-start: 10px;

                &:hover {
                    color: $btn-primary-color;
                }
    
                @include mobile {
                    color: $white;
                    margin-inline-start: 0;
                    background-color: $default-primary-base-color;
                    width: 100%;
                }
            }
        }

        .top-content {
            position: absolute;
            inset-inline-end: 20px;
            inset-block-start: 20px;

            @include mobile {
                inset-inline-start: 0px;
                inset-inline-end: inherit;
                inset-block-start: 15px;
            }

            h5 {
                color: $white;
                font-size: 12px;

                @include mobile {
                    font-size: 9px;
                    color: $white;
                }
            }
        }

        // .banner-content {
        //     inset-inline-start: 0;
        //     inset-block-end: inherit;
        //     inset-block-start: 50%;
        //     transform: translateY(-50%);
        //     padding: 15px;
        //     padding-block-end: 40px;

        //     @include mobile {
        //         inset-inline-start: 0;
        //         inset-block-end: 0;
        //         inset-block-start: inherit;
        //         transform: inherit;
        //         padding: 0;
        //         width: 100%;
        //     }

        //     .banner-top-content {
        //         margin-block-end: 100px;

        //         h2 {
        //             font-size: 20px;
        //             margin: 0;
        //         }

        //         h4 {
        //             font-size: 14px;
        //             margin: 0;
        //             inset-block-start: 0;
        //         }

        //         @include mobile {
        //             display: none;
        //         }
        //     }

        //     h2 {
        //         @include mobile {
        //             margin-block-start: 0;
        //         }
        //     }
     
        //     p {
        //         margin-block-end: 25px;
        //         padding-block-start: 6px;

        //         @include desktop {
        //             color: $black;
        //             padding-inline-start: 0;
        //         }

        //         @include mobile {
        //             margin-block-end: 0;
        //             margin-block-start: 10px;
        //         }
        //     }

        //     .button-primary {
        //         // @include mobile {
        //         //     display: block;
        //         //     width: 212px;
        //         //     margin: 0 auto;
        //         // }
        //     }
        // }
    }

    .fifth-row {
        .banner-content {
            inset-inline-start: 60px;
            
            @include desktop {
                inset-inline-end: 10px;
                inset-block-end: 25px;
            }

            @include mobile {
                margin-block-start: 10px;
                margin-block-end: 40px;
                inset-inline-start: 0;
            }

            h2 {
                margin-block-end: 0;

                @include desktop {
                    color: $white;
                }

                @include mobile {
                    margin-block-start: 0;
                    margin-block-end: 15px;
                }
            }

            p {
                @include desktop {
                    color: $white;
                }
            }


            .button-primary {
                &:hover {
                    color: $btn-primary-color;
                }
                
                @include mobile {
                    display: block;
                    width: 212px;
                    margin: 0 auto;
                }
            }
        }
    }

    .sixth-row {
        margin-block-start: 70px;
        margin-block-end: 40px;
        padding-block-end: 50px;

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 175px;
            background-color: $defult-primary-background-color;
            inset-block-end: 0;
        }

        .pagebuilder-column-group {
            width: 85%;
            margin-inline-start: auto;
            margin-inline-end: auto;
            position: relative;
            z-index: 1;

            .pagebuilder-column {
                padding: 0 48px;

                a {
                    display: block;

                    img {
                        width: 100%;
                    }
                }

                figcaption {
                    color: $white;
                    font-weight: 600;
                    margin-block-start: 20px;
                }
            }
        }
    }

    .sixth-row-mob {
        padding: 20px 0;
        background-color: $defult-primary-background-color;
        margin-inline-start: -14px;
        margin-inline-end: -14px;
        margin-block-end: 25px;
        padding-inline: 5px;

        .SlickSlider {
            padding: 0;
            
            .pagebuilder-slide-wrapper {
                // background-size: contain !important;

                .pagebuilder-overlay {
                    align-items: flex-end;
                    padding: 40px;
                    position: absolute;
                    inset-block-start: 50%;
                    transform: translateY(-50%);
                    
                    .pagebuilder-slide-button {
                        text-transform: none;
                    }
                }
            }

            .slick-slide {
                padding: 0 5px;
            }
        }
    }

    .seventh-row {
        margin-block-end: 30px;
        margin-block-start: 30px;

        img {
            width: 100%;
        }

        .pagebuilder-column-group {
            align-items: flex-end;

            .pagebuilder-column {
                align-self: auto ;
                
                @include mobile {
                    max-width: 50%;
                }
            }
        }

        .pagebuilder {
            &-banner {
                &-wrapper {
                    height: 672px;
                    background-position: center;

                    @media (max-width: 500px) {
                        height: 300px;
                        margin-block-end: 40px;
                    }
                }
            }
        }

        .pagebuilder-video-inner {
            .pagebuilder-video-container {
                padding-block-start: inherit;
                
                video {
                    position: inherit;
                }
            }
        }

        .banner-content {
            inset-inline-start: 40px;
            inset-block-end: 40px;
            inset-block-start: inherit;
            width: 300px;
            transform: inherit;

            @include mobile {
                inset-inline-start: 0;
                inset-block-end: 0;
                width: 100%;
            }

            h2 {
                @include desktop {
                    color: $white;
                }

                @include mobile {
                    margin-block-start: 0;
                }
            }
     
            p {
                margin-block-end: 25px;

                @include desktop {
                    color: $white;
                    padding-inline-end: 0;
                    padding-inline-start: 0;
                }

                @include mobile {
                    margin: 0;
                    margin-block-end: 30px;
                }
            }

            .button-primary {
                @include desktop {
                    background-color: $white;
                    color: $black;
                    margin-inline-start: 0;

                    &:hover {
                        color: $btn-primary-color;
                    }
                }

                // @include mobile {
                //     display: block;
                //     width: 212px;
                //     margin: 0 auto;
                // }
            }
        }
    }
}

.MobileView, .desktop-view, .desktop-view-button {
    display: none !important;
}

.desktop-view {
    display: flex !important;
  
    @include mobile {
        display: none !important;
    }
}

.desktop-view-button {
    display: block !important;
  
    @include mobile {
        display: none !important;
    }
}

.MobileView {
    @include mobile {
        display: flex !important;
    }
}

.CollectionBanners {
    @media screen and (max-width: 450px) {
        height: 280px;
    }
    
    @media screen and (max-width: 400px) {
        height: 280px;
    }
}

.OneThirdSlider {
    .SlickSlider {
        .slick-list {
            padding-inline-start: 0px !important;

            @include desktop {
                padding-inline-end: 0px !important;
            }
        }

        .slick-slide {
            padding: 0 15px 0 0;
            width: 300px;
        }
    }

    .pagebuilder-poster-overlay {
        margin-block-end: 0;
    }

    .pagebuilder-overlay {
        .pagebuilder-poster-content {
            position: absolute;
            inset-block-end: 0;
            width: 100%;
            z-index: 999;
            background: #fff;
            color: $black;
            padding: 5px;

            p {
                margin: 0;
            }

            div[data-element='content'] {
                min-height: inherit;
            }

            @media screen and (max-width: 450px) {
                inset-block-end: 0;
            }

            @media screen and (max-width: 420px) {
                inset-block-end: 0;
            }

            @media screen and (max-width: 400px) {
                inset-block-end: 0;
            }
        }
    }
}

.Infinity-heading {
    h1, h2 {
        margin-block-end: 10px !important;
    }

    p {
        @include desktop {
            margin: auto !important;
        }
    }
}

.InfinityOneThirdSlider {
    .SlickSlider {
        .slick-slide {
            padding: 0 10px 0 0;
        }
    }
    
    .pagebuilder-overlay {
        .pagebuilder-poster-content {
            p {
                margin: auto;
            }
        }
    }
}

.CollectionBanners.pdp {
    height: inherit;
}

.OneThirdSlider.pdp {
    .SlickSlider {
        .slick-slide {
            padding-block-end: 70px;
        }
    }

    .pagebuilder-overlay {
        .pagebuilder-poster-content {
            inset-block-end: -70px;
        }
    }
}


.product-slider {
    @include desktop {
        padding-block-end: 30px;
        padding-block-start: 30px;
    }
}

.shop-for-all-block {
    margin-block-end: 40px;

    .pagebuilder-column-group {
        flex-wrap: wrap;

        @include desktop {
            grid-gap: 24px;
        }

        @include mobile {
            grid-gap: 16px;
        }

        .pagebuilder-column {
            width: calc(25% - 20px) !important;
            margin-block-end: 10px;

            @include mobile {
                width: calc(50% - 8px) !important;
            }

            figcaption, .pagebuilder-button-link {
                font-size: 12px;
                letter-spacing: 1px;
                margin-block-start: 20px;
                font-weight: 700;
                color: $default-primary-light-color;
                
                @include mobile {
                    text-decoration: underline;
                }
            }

            .pagebuilder-button-link {
                margin-block-start: 15px;
                display: block;
            }
        }
    }
}

.LoginAccount {
    .Terms-condition {
        font-size: 12px;
        color: $btn-primary-color;
        line-height: 20px;

        a {
            font-size: inherit;
        }
    }
}

.tf-v1-sidetab-button {
    background: $defult-primary-background-color !important;
    font-size: 14px !important;
    color: $white !important;

    &-icon {
        display: none;
    }

    &-text {
        color: $white !important;
        font-size: 14px !important;
    }
}

a {
    @include mobile {
        color: #1d1d1d
    }
}



.CountryDropDown-Main {
    inset-inline-end: 20px;

    .Overlay {
        &_isVisible {
            inset-block-start: 28px;
            width: 180px;
        }
    }
}



video::-webkit-media-controls {
    display: none !important;
}

*::-webkit-media-controls-panel {
    display: none !important;
    -webkit-appearance: none;
}

*::--webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
}

*::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
}


.home-links .pagebuilder-button-primary {
    background: none !important;
    border: none !important;
    color: #000 !important;
    padding: 0 !important;
    line-height: inherit !important;
    height: auto !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
}

.home-links:hover .pagebuilder-button-primary {
    text-decoration: underline !important;
}


.zidd-heading {
    @include desktop {
        margin: 40px auto 30px;
        text-align: center;
    }

    @include mobile {
        width: 100%;
        margin: 35px auto 21px;
        text-align: center;
    }

    &.video {
        margin-block-end: 80px;

        @include mobile {
            margin-block-end: 35px;
        }
    }

    h1 {
        color: $black;
        margin-block-end: 10px;

        @include mobile {
            padding-inline-start: 0;
            padding-block-start: 10px;
        }
    }

    h2 {
        color: $black;

        @include mobile {
            padding-block-start: 25px;
        }
    }

    h1, h2 {
        font-size: 64px;
        font-family: $font-NeuePlakBlack-Condensed;
        letter-spacing: 2px;
        line-height: 1em;
        text-transform: uppercase;
        
        @media(max-width: 1100px) {
            font-size: 45px;
        }

        @include mobile {
            font-size: 35px;
            text-align: center;
            color: $default-primary-base-color;
        }

        @media (max-width: 370px) {
            font-size: 28px;
        }
    }

    p {
        text-align: center;
        margin: 0 auto;

        @include desktop {
            max-width: 508px;
        }

        @media (max-width: 900px) {
            max-width: 100%;
            font-weight: 600;
        }
    }
}

a.participate-in-challenge {
    margin: 20px 0 0 0;
    color: #fff;
    font-style: normal;
    font-weight: 600;
    text-decoration: none;
    font-size: 15px;
    text-transform: none;
    padding: 15px 30px;
    border-radius: 5px;
    border: 1px solid #000000;
    background: #000;
    display: none;

    @include mobile {
        position: fixed;
        inset-block-end: 0;
        inset-inline-start: 0;
        width: 100%;
        z-index: 99;
        border-radius: 0;
    }
}

.zidd-second-row {
    h2 {
        font-size: 64px;
        font-family: $font-NeuePlakBlack-Condensed;
        letter-spacing: 2px;
        line-height: 1em;
        text-transform: uppercase;
        color: #000;
        
        @media(max-width: 1100px) {
            font-size: 45px;
        }

        @include mobile {
            font-size: 35px;
            text-align: center;
            color: $default-primary-base-color;
        }

        @media (max-width: 370px) {
            font-size: 28px;
        }
    }

    .pagebuilder-column-group {
        .pagebuilder-column {
            padding: 0 20px 20px 20px;

            .pagebuilder-button-primary {
                padding: 0;
                background: transparent;
                border: none;
                color: #000;
                font-size: 16px;
            }
        }
    }
}

.zidd-blog {
    .pagebuilder-column {
        padding: 20px;

        h3 {
            font-size: 40px;
            font-family: $font-NeuePlakBlack-Condensed;
            letter-spacing: 2px;
            line-height: 1em;
    
            @include mobile {
                font-size: 27px;
                text-align: center;
                color: $default-primary-base-color;
            }
        }
    }
}

.ZiddFeed {
    margin-block-end: 40px;
    
    @include mobile {
        margin-block-start: 35px;
    }

    &-Wrapper {
        grid-gap: 10px;
        display: flex;
        flex-wrap: wrap;

        @include desktop {
            grid-template-columns: repeat(4, 1fr);
            display: grid;
        }

        .Image_ratio_square {
            padding-block-end: 0;
        }

        .Image-Image {
            position: relative;
            object-fit: cover;
        }
    }

    &-Item {
        img {
            width: 100%;
        }

        @include mobile {
            flex-basis: calc(50% - 5px);
        }


        &:nth-child(1) {
            grid-column: span 2;
            grid-row: span 2;

            @include mobile {
                flex-basis: 100%;
            }
        }

        &:nth-child(6) {
            grid-column: 3 / span 2;
            grid-row: 3 / span 2;

            @include mobile {
                flex-basis: 100%;
            }
        }

        &:nth-child(11) , &:nth-child(21), &:nth-child(31), &:nth-child(41) {
            grid-column: span 2;
            grid-row: span 2;

            @include mobile {
                flex-basis: 100%;
            }
        }

        &:nth-child(16) {
            grid-column: 3 / span 2;
            grid-row: 7 / span 2;

            @include mobile {
                flex-basis: 100%;
            }
        }

        &:nth-child(26) {
            grid-column: 3 / span 2;
            grid-row: 11 / span 2;

            @include mobile {
                flex-basis: 100%;
            }
        }

        &:nth-child(36) {
            grid-column: 3 / span 2;
            grid-row: 15 / span 2;

            @include mobile {
                flex-basis: 100%;
            }
        }

        &:nth-child(46) {
            grid-column: 3 / span 2;
            grid-row: 19 / span 2;

            @include mobile {
                flex-basis: 100%;
            }
        }
    }
}



.zidd-banner-row-page {
    margin-inline-end: auto;
    margin-inline-start: auto;
    
    @include desktop {
        padding-block-end: 40px;
        width: 100%;
        padding-block-start: 20px;
    }

    @include mobile {
        margin-block-start: 15px;
        margin-block-end: 15px;
    }

    h3 {
        font-size: 37px;
        font-family: $font-NeuePlakBlack-Condensed;
        letter-spacing: 2px;
        line-height: 1em;
        text-transform: uppercase;
        color: #000000;
        
        @media(max-width: 1100px) {
            font-size: 33px;
        }

        @include mobile {
            font-size: 27px;
            text-align: center;
            color: $default-primary-base-color;
        }

        @media (max-width: 370px) {
            font-size: 22px;
        }
    }

    .pagebuilder-column {
        padding: 15px;

        @include mobile {
            padding: 0 15px;
        }

        &.half-width {
            @include mobile {
                max-width: 50%;
                padding: 10px 10px 15px 10px;
            }
        }  
    }

    figure {
        img {
            width: 100%;
        }
     
        figcaption {
            font-size: 14px;
            font-weight: 600;

            @include mobile {
                font-size: 12px;
                text-decoration: underline;
            }
        }

        &:hover {
            figcaption {
                text-decoration: underline;
            }
        }
    }
}

.desktop_video_zidd .pagebuilder-video-container {
    padding-block-start: 35% !important;
}

.winter-collection-web-block {
    .pagebuilder-button-primary {
        span {
            font-size: 19px;
            font-weight: 700;
            margin: 12px 0;
        }
    }
}
