.cms-banner {
    margin-block-end: 40px;
    
    @include mobile {
        margin-inline: -14px;
    }

    .pagebuilder {
        &-banner {
            &-wrapper {
                min-height: 450px;

                @include desktop {
                    min-height: 740px;
                }
            }
        }

        &-overlay {
            min-height: inherit;
        }

        &-poster {
            &-content {
                h2 {
                    margin: 0;

                    span {
                        font-family: $font-NeuePlakBlack-Condensed;
                        color: $white;
                        font-size: 64px;
                        line-height: 1.1;

                        @include mobile {
                            /* stylelint-disable-next-line declaration-no-important */
                            font-size: 36px !important;
                        }
                    }
                }
            }
        }
    }

    &.innovation-banner {
        .pagebuilder {
            &-poster {
                &-overlay {
                    @include mobile {
                        /* stylelint-disable-next-line declaration-no-important */
                        min-height: 450px !important;
                    }
                }
            }
        }
    }
}

.cms-link-banner-2 {
    @include mobile {
        /* stylelint-disable-next-line declaration-no-important */
        min-height: 360px !important;
        margin-inline: -14px;
    }

    .shop_ua {
        span {
            font-size: 12px;
            text-transform: capitalize;
        }

        @include mobile {
            margin: 0 auto;
            width: 80%;
        }
    }
}

.cms-middle-content-info {
    .pagebuilder-column-group {
        .middle-content {
            padding: 40px 0px;
    
            @include desktop {
                padding: 55px 100px;
            }

            &.pb-0 {
                @include desktop {
                    padding-block-end: 0;
                }
            }
        }
    
        h2 {
            font-family: $font-NeuePlakBlack-Condensed;
            line-height: 1.1;
            letter-spacing: 1px;
            font-size: 48px;
            margin: 0 auto 15px;
            
            @include desktop {
                width: 95%;
            }
    
            @include mobile {
                /* stylelint-disable-next-line declaration-no-important */
                font-size: 26px !important;
                width: 68%;
            }
        }

        h4 {
            line-height: 1.3;
            letter-spacing: 1px;
            font-size: 14px;
            margin: 0 auto;
            
            @include mobile {
                /* stylelint-disable-next-line declaration-no-important */
                font-size: 14px !important;
            }
        }

        .small-title {
            p {
                font-weight: 600;
                font-size: 16px;

                @include mobile {
                    margin-block-end: 10px;
                }
            }
        }

        .mt-20 {
            margin-block-start: 20px;
        }

        p {
            padding: 0px 36px;

            @include mobile {
                padding: 0px 7px;
            }
        }
    }
}

.pagebuilder-poster-overlay {
    margin-block-end: 28px;
}

.cms-banner-3 {
    @include mobile {
        /* stylelint-disable-next-line declaration-no-important */
        min-height: 360px !important;
    }
}

.Infinity-heading {
    @include desktop {
        margin: 40px auto 30px;
        text-align: center;
    }

    @include mobile {
        width: 100%;
        margin: 35px auto 21px;
        text-align: center;
    }

    &.video {
        margin-block-end: 80px;

        @include mobile {
            margin-block-end: 35px;
        }
    }

    .Top {
        /* stylelint-disable-next-line declaration-no-important */
        margin-block-end: 10px !important;
        font-weight: 600;

        @include mobile {
            margin-block-start: 10px;
            margin-block-end: 0;
            /* stylelint-disable-next-line declaration-no-important */
            padding: 0 !important;
        }
    }

    h1 {
        color: $black;

        @include mobile {
            padding-inline-start: 0;
            padding-block-start: 10px;
        }
    }

    h2 {
        color: $black;

        @include mobile {
            padding-block-start: 25px;
        }
    }

    h1, h2 {
        font-size: 64px;
        font-family: $font-NeuePlakBlack-Condensed;
        letter-spacing: 2px;
        line-height: 1em;
        
        @media(max-width: 1100px) {
            font-size: 45px;
        }

        @include mobile {
            font-size: 35px;
            text-align: center;
            color: $default-primary-base-color;
        }

        @media (max-width: 370px) {
            font-size: 28px;
        }
    }

    p {
        text-align: center;
        font-weight: 600;
        color: #000;

        @include desktop {
            max-width: 508px;
        }

        @media (max-width: 900px) {
            max-width: 100%;
            font-weight: 600;
        }
    }

    a {
        margin: 15px 0 0 0;
        color: #070000; 
        font-style: normal; 
        font-weight: 400; 
        text-decoration: none; 
        display: inline-block; 
        background: $white;
        font-size: 13px; 
        text-transform: none; 
        padding: 9px 44px 9px 44px;
        border-radius: 5px;
        border: 1px solid $black;

        &.infinityShop {
            color: #FFF;
            font-weight: 700;  
            background: $black; 
            font-size: 16px; 
            padding: 15px 15px 15px 15px;
        }
    }
}

.infinity-slider {
    max-width: var(--content-wrapper-width);
    width: 100%;
    margin: auto;
}

.inifinity-cards {
    @include desktop {
        margin-inline-end: 15px;
        margin-inline-start: 15px;
    }
        
    h2 {
        font-size: 25px;
        margin-block-end: 18px;
        text-align: center;
        line-height: normal;

        @include mobile {
            font-size: 20px;
            text-align: center;
            color: $default-primary-base-color;
        }
    }

    p {
        width: 90%;
        margin: auto;
        text-align: center;
    }

    .pagebuilder-poster-content {
        @include mobile {
            min-height: 160px;
        }
    }
}

.InifinityCardDesktop {
    width: 90%;
    margin: 50px auto 0;
}

.desktop_video {
    display: none;

    @include desktop {
        display: block;
    }
}

.mobile_video {
    display: none;

    @include mobile {
        display: block;
    }
}

.imagePadding.pagebuilder-column {
    padding: 30px 100px;

    @include mobile {
        padding: 0;
        padding-block-start: 20px;
    }
}


.text-colum.pagebuilder-column {
    padding: 30px;
    padding-inline-start: 100px;
    padding-inline-end: 100px;

    @include mobile {
        /* stylelint-disable-next-line declaration-no-important */
        padding: 0 !important;
    }

    h1 {
        color: $black;

        @include mobile {
            padding-inline-start: 0;
            padding-block-start: 10px;
        }
    }

    h2 {
        color: $black;
    }

    h1, h2 {
        font-size: 50px;
        font-family: $font-NeuePlakBlack-Condensed;
        letter-spacing: 2px;
        line-height: 1em;
        
        @media(max-width: 1100px) {
            font-size: 35px;
        }

        @include mobile {
            font-size: 30px;
            text-align: center;
            color: $default-primary-base-color;
        }

        @media (max-width: 370px) {
            font-size: 28px;
        }
    }
}


.project-rock-button {
    /* stylelint-disable-next-line declaration-no-important */
    margin: 0 0 50px 0 !important;

    @include mobile {
        /* stylelint-disable-next-line declaration-no-important */
        margin: 0 0 30px 0 !important;
    }
}


.CmsPage-Content figure img {
    width: 100%;
}

.text-infinite {
    @include mobile {
        text-align: justify;
    }
}
