/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --breadcrumbs-height: 40px;
    --breadcrumbs-active-color: #0a0903;
    --breadcrumbs-color: #5F5F5F;
}

.Breadcrumbs {
    display: none;

    @include mobile {
        // display: block;
    }

    &-List {
        padding-block-start: 20px;
        padding-block-end: 10px;
        
        @include desktop {
            padding-block-start: 35px;
            padding-block-end: 15px;
        }
    }
}

.Custom {
    &-Breadcrumbs {
        .Breadcrumbs {
            display: block;

            .ContentWrapper {
                // padding-inline: 0;
            }
        }
    }
}
