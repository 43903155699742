/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.InstagramFeed {
    margin-block-end: 40px;
    
    @include mobile {
        margin-block-start: 35px;
    }

    &-Title {
        text-align: center;
        font-size: 24px;
    }

    &-Wrapper {
        grid-gap: 10px;
        display: flex;
        flex-wrap: wrap;

        @include desktop {
            grid-template-columns: repeat(4, 1fr);
            display: grid;
        }

        .Image_ratio_square {
            padding-block-end: 0;
        }

        .Image-Image {
            position: relative;
            object-fit: cover;
        }
    }

    &-Item {
        &:hover {
            .InstagramFeed-btnWrapper {
                opacity: 1;
            }
        }

        @include mobile {
            flex-basis: calc(50% - 5px);
        }


        &:nth-child(1) {
            grid-column: span 2;
            grid-row: span 2;

            @include mobile {
                flex-basis: 100%;
            }
        }

        &:nth-child(6) {
            grid-column: 3 / span 2;
            grid-row: 3 / span 2;

            @include mobile {
                flex-basis: 100%;
            }
        }

        &:nth-child(11) , &:nth-child(21), &:nth-child(31), &:nth-child(41) {
            grid-column: span 2;
            grid-row: span 2;

            @include mobile {
                flex-basis: 100%;
            }
        }

        &:nth-child(16) {
            grid-column: 3 / span 2;
            grid-row: 7 / span 2;

            @include mobile {
                flex-basis: 100%;
            }
        }

        &:nth-child(26) {
            grid-column: 3 / span 2;
            grid-row: 11 / span 2;

            @include mobile {
                flex-basis: 100%;
            }
        }

        &:nth-child(36) {
            grid-column: 3 / span 2;
            grid-row: 15 / span 2;

            @include mobile {
                flex-basis: 100%;
            }
        }

        &:nth-child(46) {
            grid-column: 3 / span 2;
            grid-row: 19 / span 2;

            @include mobile {
                flex-basis: 100%;
            }
        }
    }

    &-btnWrapper {
        position: absolute;
        inset-block-end: 50px;
        display: flex;
        justify-content: center;
        width: 100%;
        opacity: 0;
        pointer-events: none;

        .Button {
            @include mobile {
                width: auto;
            }
        }
    }
    
    &-content , &-Link {
        height: 100%;
        
        video {
            object-fit: cover;
        }

        &::after {
            content: '';
            position: absolute;
            inset-block-start: 0;
            inset-inline-start: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.2);
            opacity: 0;
        }

        &:hover {
            &::after {
                opacity: 1;
            }
        }
    }

    &-Link {
        display: block;
    }

    &-Icon {
        position: absolute;
        inset-block-end: 0;
        inset-inline-end: 0;
        background-color: $white;
        width: 56px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile {
            width: 50px;
            height: 50px;
        }

        svg {
            width: 30px;
            height: 30px;

            @include mobile {
                width: 27px;
                height: 27px;
            }
        }
    }

    &-LoadMoreWraper {
        padding: 30px 0 0;
        display: flex;
        justify-content: center;

        .Button {
            width: 260px;
            font-size: 16px;
            font-weight: 500;
        }
    }
}
