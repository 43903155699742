/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.CustomerServicePage {
    margin-block-end: 50px;

    &-PageHeading {
        text-transform: uppercase;
        font-weight: 700;
        width: 100%;
        margin-left: auto;
        padding: 15px 40px;
        border-bottom: 1px solid $border-color;
        margin-block-end: 10px;
        
        @include desktop {
            padding-inline-start: calc(22% + 40px);
        }

        @include mobile {
            text-align: center;
        }
    }

    &-PageWrapper {
        margin-block-start: 40px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        @include mobile {
            margin-block-start: 20px;
        }
    }

    &-ContentDetail {
        flex: 1;
        
        @include desktop {
            padding-left: 40px;
        }

        .CmsPage {
            min-height: auto;

            @include mobile {
                margin-block-start: 0;
            }
        }

        .CmsPage-Wrapper  {
            margin-block-start: 0;
            margin-block-end: 10px;

            .CmsPage-Content {
                .cms-content {
                    ul {
                        li {
                            &::before {
                                padding-right: 0;
                                top: 8px;
                                width: 4px;
                                height: 4px;
                                border-radius: 50%;
                                background-color: $default-primary-base-color;
                            }
                        }
                    }
                }
            }
        }

        .Table-Wrapper {
            .data-table {
                @include mobile {
                    width: 100%;
                }
            }
        }

        .contact-information {
            @include desktop {
                border-left: 1px solid $border-color;
                padding-left: 10px
            }

            .phone-support , .email-support {
                .icon__circle {
                    width: 30px;
                    height: 30px;
                    background-color: $default-primary-base-color;
                    fill: $white;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    stroke-width: 25;
                    border-radius: 50%;

                    svg {
                        width: calc(100% - 15px);
                        height: calc(100% - 15px);
                    }
                }
            }

            .support-info {
                display: grid;
                line-height: 18px;
                margin-block-start: 10px;
                font-size: 12px;

                a {
                    display: block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-weight: 400;
                    font-size: 12px;
                }
            }
        }

        form {
            @include desktop {
                width: 60%;
            }
            
            .formTitle {
                margin-block-end: 0px;
            }

            input {
                width: 100%;
            }

            .Field {
                margin-block-start: 10px;
            }
            
            button {
                margin-block-start: 25px;
            }
        }
    }

    .ServiceItem {
        text-decoration: none;
        margin-block-end: 0;
        padding: 10px 20px;

        &::before {
            display: none;
        }

        &_isActive {
            background-color: $white;
        }

        a {
            display: block;
        }
    }
    
    &-ServiceList {
        padding-top: 15px;
    }

    &-ServiceListWrapper {
        background-color: $default-secondary-base-color;
        padding: 30px 20px 20px;
        width: 22%;

        @include mobile {
            display: none;
        }

        h4 {
            font-size: 16px;
            text-align: center;
            text-transform: uppercase;
            font-weight: 600;
            letter-spacing: 0.3px;
        }
    }

    .Breadcrumbs-List {
        @include mobile {
            padding-block-start: 25px;
        }
    }
}
