/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable declaration-no-important */

.NoMatch {
    @include mobile {
        min-height: auto;
    }

    .CmsPage {
        @include mobile {
            margin-block-start: 35px;
        }
    }

    .CmsPage-Wrapper {
        margin-block-start: 0;
        margin-block-end: 60px;
    }

    .noMatch-content {
        @include desktop {
            padding: 0 100px;
        }

        @include mobile {
            margin-block-end: 25px;
            text-align: center;
        }
    }

    h3 {
        font-weight: 400;
        font-size: 18px;
        line-height: 40px;
        margin-block-end: 12px;
    }

    h1 {
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
        text-transform: none;
        margin-block-end: 5px;
    }
    
    p {
        margin-block-end: 30px;
        
        @include desktop {
            width: 70%;
        }
    }

    .pagebuilder-button-primary {
        min-width: 210px;
        text-transform: none !important;
        margin-block-end: 30px !important;
    }

    .pagebuilder-button-link {
        font-size: 14px !important; 
        text-decoration: underline !important;
    }

    .mobile-hide-image {
        @include mobile {
            display: none;
        }
    }
}
