/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.SizeGuidePage {
    margin-block-end: 50px;
    
    @include desktop {
        min-height: 500px;
    }

   &-Heading {
       text-align: center;
       font-size: 20px;
       border-bottom: 2px solid $border-color;
       padding: 25px 0 20px;
       font-weight: 700;
       
       @include desktop {
            border-bottom: 2px solid $border-color;
            font-size: 28px;
       }
    }

   &-Listing {
       width: 450px;
       margin: 0 auto;
       text-align: center;

       @media(max-width:767px) {
            width:90%;
       } 

   }

   &-SectionHeading {
       font-size: 18px;
       border-bottom: 1px solid $border-color;
       padding-block-end: 15px;
       margin-block-end: 0;
       text-transform: none;

       @include desktop {
          border-bottom: 2px solid $border-color;
          font-size: 20px;
       }
   }
   
   .CategoryItem {
       list-style: none;
       border-bottom: 1px solid $border-color;

       @include desktop {
            border-bottom: 2px solid $border-color;
        }

       &::before {
           display: none;
       }

       .Link {
           padding: 15px 0;
           display: block;
           font-weight: 400;
           font-size: 16px;

           &:hover , &:focus {
               border-bottom: 0;
               padding-block-end: 15px;
           }
       } 
   }

   .SectionSubCategory, .SectionDetail {
       &-CategoryList {
           display: flex;
           margin:  0 auto;
           justify-content: center;
           
           @include desktop {
                width: 50%;
            }

           .CategoryItem {
                border-bottom: 0;
                border-right: 2px solid $border-color;
                margin-block: 15px;
                min-width: 200px;
                text-align: center;
                
                @include mobile { 
                    display: none;
                    border-right: 0;
                    width: 90%;
                }

                &:last-child {
                    border-right: 0;
                }
                
                a {
                    font-weight: 600;
                    font-size: 18px;
                    padding: 5px;
                    display: inline-block;
                    
                    @include mobile {
                        display: block;
                    }

                    &:hover , &:focus {
                        padding: 5px;
                    }
                }

                &_isSelected {
                    
                    @include mobile { 
                        display: block;
                    }
                    
                    &:after {
                        @include mobile { 
                            content: '';
                            position: absolute;
                            inset-inline-start: 0%;
                            inset-block-start: 50%;
                            width: 15px;
                            height: 15px;
                            border-left: 2px solid $black;
                            border-top: 2px solid $black;
                            transform: translateY(-50%) rotate(-45deg);
                        }
                    }

                    a {
                        color: $black;
                        
                        &:before {
                            @include desktop {
                                content: '';
                                position: absolute;
                                inset-block-end: 0;
                                width: 100%;
                                inset-inline-start: 0;
                                height: 2px;
                                background-color: $black;
                            }
                        }
                    }
                }
           }
       }
       
       &-SectionHeading {
           background-color: $default-secondary-base-color;
           padding: 25px;
           text-align: center;
           font-size: 18px;
           margin-block-end: 0;
           margin-block-start: 0;
    
           @include desktop {
              font-size: 20px;
           }
        }

        &-SubCategroyListing {
            width: 450px;
            margin: 0 auto;
            text-align: center;

            @media(max-width:767px) {
                width:90%;
            } 

            .SubCategoryItem {
                border-bottom: 1px solid $border-color;


                @include desktop {
                     border-bottom: 2px solid $border-color;
                 }
         
                &::before {
                    display: none;
                }
         
                .Link {
                    padding: 15px 0;
                    display: flex;
                    align-items: center;
                    font-weight: 400;
                    padding-inline-start: 40%;
                    font-size: 16px;

                    svg {
                        width: 40px;
                        height: 40px;
                        margin-inline-end: 10px;
                        fill: #8c8c8c;
                    }

                    &:hover , &:focus {
                        border-bottom: 0;
                        padding-block-end: 15px;
                    }
                } 
            }
        }
    }

    .SectionDetail {
        &-SubCategroyListing {
            display: flex;
            width: 52%;
            justify-content: center;
            
            @include mobile {
                justify-content: flex-start;
                width: 100%;
                overflow: auto;
            }
            
            .SubCategoryItem {
                border-bottom: 0;
                margin-block-end: 0;
                margin:  0 25px;
                
                @include desktop {
                    margin: 0 35px;
                }

                .Link {
                    padding-inline-start: 0;
                    flex-direction: column;
                    align-items: center;
                    font-size: 12px;
                    color: #8c8c8c;
                    svg {
                        margin-inline-end: 0;
                        height: 50px;
                        width: 50px;
                    }
                }

                &_isSelected {

                    @include mobile {
                        order:-1;
                    }

                    .Link {
                        color: $black;
                        font-weight: 600;

                        svg {
                            fill: #000;
                        }
                    }

                    
                }
            }
        }

        &-SubCategroyListingWrapper {
            background-color: $default-secondary-base-color;
            padding: 0 25px;
            text-align: center;  
        }
    }


    .fit-size {
        h5 {
            text-align: center;
        }
    }
}


.Size_Guide_Page {
    width: 70%;
    margin: 0 auto;
    padding: 30px 0;
    
    @include mobile {
        width: 100%;
        padding: 20px 20px 0;
    }
    
    .pagebuilder-column-group {
        @include mobile {
            flex-direction: column-reverse;
        }
    }

    .left-content {
        @include desktop{
            width: 60%;
        }

        @include mobile {
            padding-block-start: 20px;
            text-align: center;
        }
    }

    .right-content {
        @include desktop{
            width: 40%;
        }
    }

    .col-8 {
        width: 75%;
    }

    .col-4 {
        padding: 0 20px;
        
        @include desktop {
            width: 33.33%;
        }
    }

    h2 {
        font-size: 28px;
        margin-block-start: 0;
        line-height: 1em;
    }

    h4 {
        font-weight: 700;
        
        @include mobile {
            font-size: 15px;
            margin-block-start: 15px;
            margin-block-end: 15px;
            text-align: center;
        }
    }

    h5 {
        font-size: 14px;
    }

    p {
        @include desktop {
            font-size: 12px;
        }
    }

    .sizeguide_tab {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        margin-block-end: 30px;

        .Table-Wrapper {
            table {
                width: 100% !important;
            }

            tr {
                td {
                    font-size: 13px;
                }
                
                // &:nth-child(odd) {
                //     background-color: $default-secondary-base-color;
                // }

                // &:first-child {
                //     background-color: transparent;
                // }
            }

            p {
                font-size: 14px;
            }
        }

        .tabs-navigation {
            display: flex;
            justify-content: center;
            border:1px solid #8c8c8c;
            border-radius: 18px;

            li {
                margin-block-end: 0;
                border-radius: 18px;

                &::before {
                    display: none;
                }

                a {
                    color : #8c8c8c; 
                    font-weight: 400;
                    padding: 5px 30px 3px;
                    display: block;    
                }

                &.ui-state-active {
                    background-color: $default-primary-base-color;

                    a {
                        color: $white;
                    }
                }
            }
        }
    }
}

.ProductSizeChartPopup {
    .Size_Guide_Page {
        width: 100%;
    }
}


