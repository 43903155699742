/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.FieldSelect {
    // TODO style the element

    &-Option {
        &_isSelected {
            font-weight: 600;

            @include desktop {
                &::after {
                    content: '';
                    position: absolute;
                    inset-block-start: 9px;
                    inset-inline-end: 12px;
                    border-bottom: 1px solid $black;
                    border-left: 1px solid $black;
                    width: 8px;
                    height: 4px;
                    transform: rotate(-45deg);
                }
            }
        }

        &_isPlaceholder {
            pointer-events: none;
        }
    }
    
    &-Options {
        @include mobile {
            &_isExpanded {
                z-index: 15;
                max-height: 200px;
                border-color: var(--input-border-color);
                overflow-y: auto;
                background-color: #{$white};
            }
        }
    }
    
    &-Select {
        min-width: 100%;
        padding-inline-end: 40px;
        width: 100%;
        pointer-events: none;

        &_isExpanded {
            border-color: var(--input-border-color-focus);
            opacity: 1;
        }
    }
}
