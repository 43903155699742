/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.SiteMap {
    margin-block-end: 40px;
    @include mobile {
        padding-block-start: 20px;
    }
   &-Main-Title {
        margin: 2.5rem 0;
        text-transform: uppercase;

        @include mobile {
            font-size: 24px;
        }
   }

   &-Section {
       border-bottom: 1px solid $border-color;
   }

   &-Title {
    font-size: 30px;
    @include mobile {
        font-size: 20px;
    }

       a {
           font-size: inherit;
           font-weight: 600;
       }
   }

   &-SubCategory-Section {
       display: flex;
       flex-wrap: wrap;

       li{
           width: 50%;
           @include desktop {
            width: 25%;
           }

           &:before {
               display: none;
           }

           a {
               font-weight: 400;

               &:hover {
                   text-decoration: underline;
                   color: blue;
               }
           }
       }
   }
}
