/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ResetAttributes {
    &-Title {
        font-size: 16px;
        font-family: 600;
        border-bottom: 1px solid $border-color;
    }

    div {
        display: inline-block;
    }

    &-AttributeValue {
        border: 1px solid $default-primary-base-color;
        margin-inline-end: 10px;
        /* stylelint-disable-next-line declaration-no-important */
        // display: flex !important;
        // flex-direction: row-reverse;
        padding: 0 20px 0 10px;
        border-radius: 4px;
    }

    &-CloseIcon {
        width: 10px;
        height: 10px;
        // margin-block-start: -3px;
        position: absolute;
        inset-inline-end: 8px;
        inset-block-start: 8px;

        @include mobile {
            inset-block-start: 6px;
        }
        
        .CloseIcon {
            width: 10px;
            height: 10px;
        }
    }

    &-AttributeText {
        padding: 8px 0 6px;
        inset-inline-start: 0;
        margin-inline-end: 8px;
        font-size: 11px;
    }

    &-AttributeLabel {
        display: none;
    }
}
