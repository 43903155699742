/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --popup-background: rgba(0, 0, 0, 0.8);

    @include mobile {
        --popup-content-padding: 14px;
    }

    --popup-content-background: #{$white};
    --popup-min-width: 100%;
    --popup-content-padding: 12px;

    @include desktop {
        --popup-min-width: 490px;
        --popup-content-padding: 24px;
    }
}

.Popup {
    align-items: baseline;
    overflow: auto;
    
    .Popup {
        &-CloseBtn {
            // position: relative;
            
            @include mobile {
                inset-inline-end: 0;
                inset-block-start: 0;
            }

            .CloseIcon {
                width: 18px;
                height: 18px;
                padding: 3px;

                @include mobile {
                    width: 14px;
                    height: 14px;
                    padding: 0px;
                }
            }
        }

        &-Heading {
            font-size: 24px;
            font-weight: 600;
            margin-block-end: 5px;
            line-height: 30px;
        }
    }

    &-Content {
        border-radius: 0;

        @include mobile {
            padding: 30px 20px 20px;
            min-width: 100%;
            overflow-x: hidden;
        }
    }

    &-Header {
        @include mobile {
            display: block;
        }
    }
    
    &_isVisible {
        @include mobile {
            inset-block-start: 0;
            height: 100%;
        }
    }

    &-mainWrapper {
        margin: 2rem auto;
        min-height: calc(100% - 4rem);
        display: flex;
        align-items: center;

        @include mobile {
            width: calc(100% - 20px);
            margin: 10px auto;
        }
    }

    &.CancelOrderPopup {
        .Button-Group {
            .Button {
                margin-inline-end: 10px;
            }
        }
    }

    @include mobile {
        .NotificationList {
            display: none;
        }    
    }   
}

.NewVersionPopup-Heading {
    @include mobile {
        display: none;
    }
}



.scrollDisabled {
    margin-block-start: 0;
    width: 100%;

    @include desktop {
        position: inherit;
        overflow-y: auto;
    }

    @include mobile {
        overflow-y: auto;
    }
}

.ProductSizeChartPopup {
    table {
        /* stylelint-disable-next-line declaration-no-important */
        width: 100% !important;
    }
}
