/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --expandable-content-color: var(--color-karaka);
    --expandable-content-divider-color: var(--primary-divider-color);
    --toggle-button-size: 14px;
}

@mixin expanded-content {
    opacity: 1;
    max-height: 100%;
}

.ExpandableContentFilter-Content.ExpandableContentFilter-Content_isContentExpanded_false {
    opacity: 1;
    max-height: 100%;
}

.ExpandableContentFilter {
    @include mobile {
        border-block-start: 1px solid var(--expandable-content-divider-color);
        padding-block-start: 14px;
        padding-block-end: 14px;

        &:last-of-type {
            border-block-end: 1px solid var(--expandable-content-divider-color);
        }

        .AddIcon,
        .MinusIcon,
        .ChevronIcon {
            width: var(--toggle-button-size);
            height: var(--toggle-button-size);
        }
    }

    &-Button {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include mobile {
            // padding-block-start: 14px;
            // padding-block-end: 14px;
        }
    }

    &-Heading,
    &-SubHeading {
        display: block;
        color: var(--expandable-content-color);
    }

    &-Heading {
        font-size: 16px;
        font-weight: 600;
        word-break: break-all;
        text-transform: capitalize;
        cursor: pointer;
        color: $default-primary-base-color;

        @include mobile {
            font-size: 14px;
        }
    }

    &-SubHeading {
        font-weight: 400;
        font-size: 12px;
        line-height: 1.1;
    }

    &-Content {
        // Disabled as it prevents z-index use inside
        // will-change: max-height, padding, opacity;
        transition-property: opacity;
        transition-duration: 200ms;
        max-height: 0;
        overflow: hidden;
        opacity: 0;

        &_isContentExpanded {
            @include expanded-content;
        }

        .ProductConfigurableAttributes-SwatchList {
            justify-content: flex-start;
        }

        .ProductAttributeValue {
            margin: 7px 0;
            display: block;

            @include mobile {
                padding: 2px 0;
            }
        }

        .ProductAttributeValue-Color, .ProductAttributeValue-Image-Overlay {
            // margin: 0;
        }
    }

    @include mobile {
        &_isWithoutBorder {
            border: 0;

            &:last-of-type {
                border: 0;
            }
        }
    }
}
