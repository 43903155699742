/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CountryDropdownOverlay {
    // TODO style the element
    color: $black;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
    border: 1px solid $default-primary-base-color;
    border-top: 2px solid $white;
    border-radius: 0 0 4px 4px;
    padding: 20px;

    &> span {
        font-weight: 600;
        font-size: 16px;
        margin: 10px 0;
        display: block;
    }

    ul {
        list-style-type: square;
    }

    li {
        margin: 0;
        margin-block-start: 12px;

        &::before {
            display: none;
        }

        a {
            display: flex;
            justify-content: left;
            align-self: center;
            gap: 10px;

            &::before {
                content: "";
                background: url('flag-sprite-UA.png') no-repeat top left;
                width: 18px;
                height: 12px;
                margin-block-start: 4px;
            }

            &.US {
                &::before {
                    background-position: 0 -39px;
                }
            }

            &.SG {
                &::before {
                    background-position: 0 -21px;
                }
            }
        }

        img {
            width: 18px;
        }

        span {
            font-size: 1.2rem;
            font-weight: 400;
        }

        &:last-child {
            border-top: 2px solid $border-color;

            a {
                font-size: 12px;
                color: $btn-primary-color;
                font-weight: 600;
                justify-content: center;
                margin-block-start: 2.3rem;
                text-transform: capitalize;

                &::before {
                    display: none;
                }
            }
        }
    }
}
