/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.GuestReturn {
    &-Wrapper {
        padding-block-start: 30px;
        padding-block-end: 30px;

        @include mobile {
            padding-block-start: 0;
        }

        @include desktop {
            display: flex;
            align-items: center; 
            flex-wrap: wrap;
        }
    }

    &-Content {
        @include desktop {
            width: 45%;
            padding-inline-end: 50px;
        }

        h3 {
            @include mobile {
                font-size: 22px;
            }
        }

        h4 {
            font-size: 22px;
            border-bottom: 1px solid black;
            padding-bottom: 15px;
        }
    }

    &-Submit {
        width: 100%;
        margin-block-start: 25px;

        button {
            width: 100%;
        }

        .Button[disabled] {
            opacity: 1;
        }
    }

    .Field {
        input {
            width: 100%;
        }
    }
}
