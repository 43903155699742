/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --loader-scale: 1;
}

.Loader {
    // background-color: rgba(255, 255, 255, .6);
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    inset-block-end: 0;
    z-index: 75;
    margin: auto;
    opacity: 0;
    // animation: appear 1ms 200ms forwards;

    &-Main {
        // @extend %loader;
    }

    &-Scale {
        position: absolute;
        transform: translate(-50%, -50%) scale(var(--loader-scale));
        inset-inline-start: 50%;
        inset-block-start: 50%;

        &:dir(rtl) {
            transform: translate(50%, -50%) scale(var(--loader-scale));
        }
    }

    .b-loader {
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        min-height: 2.75rem;
        position: relative;
        inset-block-start: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        // background-color: rgba(255, 255, 255, 0.2);
        inset-inline-end: 0;
        opacity: .5;
        z-index: 1;
        list-style: none;

        &-icon {
            display: inline-block;
            width: 8px;
            height: 8px;
            background-color: #767676;
            opacity: 1;
            animation: fade 1s infinite;
            margin-block-end: 0;
            margin-inline-end: 6px;

            &:last-child {
                margin-block-end: 0;
                margin-inline-end: 0px;
            }

            &::before {
                display: none;
            }

            &:nth-child(2) {
                animation-delay: .166s;
            }

            &:nth-child(3) {
                animation-delay: .333s;
            }
        }
    }
}

@keyframes fade {
    0% {
        opacity: 0
    }

    10% {
        opacity: 1
    }
    
    90% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}
