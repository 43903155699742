/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.FooterSocial {
    @include desktop { 
        padding-inline: 15px;
        width: 15%;
    }

    h4 {
        font-size: 12px;
        font-weight: 600;
        display: flex;
        align-items: center;
        color: $default-primary-base-color;
        line-height: inherit;

        @include mobile { 
            font-size: 14px;
            margin-block-start: 15px;
        }
    }

    .Social-List {
        margin-block-start: 5px;
        
        li {
            margin: 20px 0;

            &::before {
                display: none;
            }
            
            a {
                font-size: 12px;
                color: $default-primary-base-color;
                margin-block-end: 3px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-weight: 400;
                line-height: 20px;
                padding-inline-start: 25px;
                position: relative;

                &::before {
                    position: absolute;
                    inset-block-start: 0;
                    inset-inline-start: 0;
                    width: 16px;
                    height: 16px;
                    content: "";
                }
            }

            &.Instagram {
                a {
                    &::before {
                        background: url(./images/instagram.png);
                    }
                }
            }

            &.Facebook {
                a {
                    &::before {
                        background: url(./images/facebook.png);
                    }
                }
            }

            &.YouTube {
                a {
                    &::before {
                        background: url(./images/youtube.png);
                    }
                }
            }
        }
    }
}
