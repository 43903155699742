/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Menu {
    &-Item {
        &.Flag {
            .Menu-ItemCaption {
                &:hover {
                    color: var(--link-hover);
                }
            }
        }
    }
    
    &-ItemCaption {
        padding-block-end: 5px;
        text-transform: capitalize;
        font-size: 16px;
        font-family: $font-NeuePlak;
        font-weight: 600;

        @include mobile {
            text-transform: inherit;
        }

        &_isCountry {
            display: flex;
            justify-content: space-between;

            div {
                display: flex;
                align-self: center;
            }

            img {
                /* stylelint-disable-next-line declaration-no-important */
                width: 27px !important;
                /* stylelint-disable-next-line declaration-no-important */
                height: 17px !important;
                margin-inline-end: 1rem;
            }
        }

        @include desktop {
            color: $white;
        }

        @include mobile {
            padding-block-start: 15px;
            padding-block-end: 15px;
            font-size: 14px;
            font-weight: 600;
            color: $default-primary-base-color;
        }

        img {
            @include mobile {
                width: auto;
                height: auto;
                margin-inline-start: 10px;
            }
        }

        &::after {
            content: "";
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 1px;
            inset-block-end: -1px;
            inset-inline-start: 0;
            background-color: $white;
            transform-origin: bottom right;
            transition: transform 0.25s ease-out;
        }

        &:hover {
            color: $white;

            &::after {
                transform: scaleX(1);
                transform-origin: bottom left;
            }
        }
    }

    &-MenuWrapper {
        flex: 1 auto;

        @include desktop {
            margin-block-start: 0;
        }

        .Menu {
            &-MainCategories {
                .Menu-ItemList {
                    @include desktop {
                        position: relative;
                    }

                    @include mobile {
                        padding-block-start: 25px;
                        padding-block-end: 122px;
                        overflow-y: scroll;
                        overflow-x: hidden;
                    }

                    @media (max-width: 900px) and (max-height: 1200px) {
                        height: 1070px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 1190px) {
                        height: 1060px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 1180px) {
                        height: 1050px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 1170px) {
                        height: 1040px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 1160px) {
                        height: 1030px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 1150px) {
                        height: 1020px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 1140px) {
                        height: 1010px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 1130px) {
                        height: 1000px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 1120px) {
                        height: 990px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 1110px) {
                        height: 980px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 1100px) {
                        height: 970px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 1090px) {
                        height: 960px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 1080px) {
                        height: 950px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 1070px) {
                        height: 940px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 1060px) {
                        height: 930px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 1050px) {
                        height: 920px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 1040px) {
                        height: 910px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 1030px) {
                        height: 900px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 1020px) {
                        height: 890px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 1010px) {
                        height: 880px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 1000px) {
                        height: 870px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 990px) {
                        height: 860px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 980px) {
                        height: 850px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 970px) {
                        height: 840px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 960px) {
                        height: 830px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 950px) {
                        height: 820px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 940px) {
                        height: 810px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 930px) {
                        height: 800px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 920px) {
                        height: 790px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 910px) {
                        height: 780px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 900px) {
                        height: 770px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 890px) {
                        height: 760px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 880px) {
                        height: 750px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 870px) {
                        height: 740px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 860px) {
                        height: 730px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 850px) {
                        height: 720px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 840px) {
                        height: 710px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 830px) {
                        height: 700px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 820px) {
                        height: 690px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 810px) {
                        height: 680px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 800px) {
                        height: 670px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 790px) {
                        height: 660px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 780px) {
                        height: 650px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 770px) {
                        height: 640px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 760px) {
                        height: 630px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 750px) {
                        height: 620px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 740px) {
                        height: 610px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 730px) {
                        height: 600px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 720px) {
                        height: 590px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 710px) {
                        height: 580px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 700px) {
                        height: 570px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 690px) {
                        height: 560px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 680px) {
                        height: 550px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 670px) {
                        height: 540px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 660px) {
                        height: 530px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 650px) {
                        height: 520px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 640px) {
                        height: 510px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 630px) {
                        height: 500px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 620px) {
                        height: 490px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 610px) {
                        height: 480px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 600px) {
                        height: 470px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 590px) {
                        height: 460px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 580px) {
                        height: 450px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 570px) {
                        height: 440px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 560px) {
                        height: 430px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 550px) {
                        height: 420px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 540px) {
                        height: 410px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 530px) {
                        height: 400px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 520px) {
                        height: 390px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 510px) {
                        height: 380px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 500px) {
                        height: 370px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 490px) {
                        height: 360px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 480px) {
                        height: 350px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 470px) {
                        height: 340px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 460px) {
                        height: 330px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 450px) {
                        height: 320px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 440px) {
                        height: 310px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 430px) {
                        height: 300px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 420px) {
                        height: 290px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 410px) {
                        height: 280px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 400px) {
                        height: 270px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 390px) {
                        height: 260px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 380px) {
                        height: 250px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 370px) {
                        height: 240px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 360px) {
                        height: 230px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 350px) {
                        height: 220px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 340px) {
                        height: 210px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 330px) {
                        height: 200px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 320px) {
                        height: 190px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 310px) {
                        height: 180px;
                    }
                    
                    @media (max-width: 900px) and (max-height: 300px) {
                        height: 170px;
                    }
                    

                    &_type_subcategory {
                        @include mobile {
                            padding-block-start: 0;
                            height: 100%;
                            overflow-y: scroll;
                        }
                    }

                    .Menu-Item {
                        height: auto;
                        // padding: 14px 0;

                        // @include mobile {
                        //     padding: 14px 0;
                        // }
                        
                        &:last-child {
                            margin-block-end: 0px;
                        }

                        &:not(:first-of-type) {
                            @include desktop {
                                margin-inline-start: 20px;
                            }

                            @include wide-desktop {
                                margin-inline-start: 60px;
                            }
                        }

                        figcaption {
                            margin-block-end: 0;

                            @include desktop {
                                padding-block-end: 0;
                            }

                            &:hover {
                                @include mobile {
                                    color: $default-primary-base-color;
                                }
                            }

                            .DownArrow {
                                @include mobile {
                                    transform: rotate(-92deg);
                                }
                            }

                            &.Menu-ItemCaption_isExpanded {
                                .Menu-ExpandedState {
                                    &_isExpanded {
                                        @include mobile {
                                            position: fixed;
                                            inset-block-start: 16px;
                                            inset-inline-start: 10px;
                                            inset-inline-end: auto;
                                            z-index: 99;

                                            svg {
                                                transform: rotate(90deg);
                                            }
                                        }
                                    }

                                    &_isSecondLevel {
                                        @include mobile {
                                            inset-block-start: 0;
                                        }
                                    }
                                }
                            }
                        }

                        &.Menu-Item_isIcon {
                            display: flex;
                            align-items: center;

                            .Image {
                                padding: 0;
                                margin-inline-start: 5px;
                                width: 13px;

                                img {
                                    position: relative;
                                }
                            }
                        }

                        .Menu-SubMenu {
                            @include mobile {
                                opacity: 0;
                                transition: transform 0.4s, opacity 0.4s;
                                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                                transition-delay: 0.18s;
                                will-change: transform;
                                pointer-events: none;
                                transform: translateX(100%);
                                z-index: 999;
                                background: $white;
                                position: fixed;
                                inset-block-start: 50px;
                                inset-inline-start: 0;
                                width: 100vw;
                                // height: calc(100% - 50px);
                                height: 100%;
                            }

                            &_isVisible {
                                transform: translateX(0);
                                opacity: 1;
                                pointer-events: all;
                            }

                            .Menu-ItemCaption {
                                @include mobile {
                                    padding-block-start: 18px;
                                    padding-block-end: 18px;
                                    margin-inline-start: 0;
                                }

                                &_isSecondLevel {
                                    .Menu-ExpandedState {
                                        &_isExpanded {
                                            inset-block-start: -34px;
                                        }
                                    }

                                    &.Menu-ItemCaption_isExpanded {
                                        & + .Menu-SubMenu {
                                            inset-block-start: 0px;
                                        }
                                    }
                                }
                            }
                        }

                        .Submenu {
                            &:first-of-type {
                                position: fixed;
                                inset-block-start: 100px;
                                inset-inline-start: 50%;
                                transform: translateX(-50%);
                                width: var(--content-wrapper-width);
                                z-index: 9;
                                justify-content: space-around;
                                background-color: $white;
                                padding: 35px 40px;
                                margin: 0 auto;
                                display: none;
                                
                                @media (max-width: 1440px) {
                                    width: 100%;
                                }

                                a {
                                    color: $default-primary-base-color;
                                    font-size: 16px;
                                    font-weight: 600;
                                    padding-block-end: 0px;
                                    margin-block-end: 10px;
                                    
                                    &::after {
                                        background-color: $default-primary-base-color;
                                    }
                                }

                                .menu-img {
                                    max-width: var(--content-wrapper-width);
                                    margin: 0 auto;
                                }
                            }

                            .subMenu-banner {
                                flex-basis: 30%;
                                padding-inline-end: 20px;
                                display: flex;
                                flex-direction: column;

                                .menu-img {
                                    position: relative;
                                    width: 100%;
                                    height: 100%;
                                    padding-block-end: 100%;
                                    overflow: hidden;
                                    display: inline-block;

                                    img {
                                        width: 100%;
                                        position: absolute;
                                        inset-block-start: 0px;
                                        object-fit: contain;
                                        height: 100%;
                                        object-position: 50% 50%;
                                    }
                                }

                                h4 {
                                    font-size: 14px;
                                    line-height: 20px;
                                    font-weight: 600;
                                    -webkit-margin-before: 10px;
                                    margin-block-start: 10px;
                                }

                                a.link {
                                    color: $default-primary-base-color;
                                    font-size: 14px;
                                    font-weight: 400;
                                    padding-block-end: 0px;
                                    margin-block-start: 3px;
                                    margin-block-end: 15px;
                                    text-decoration: underline;
                                    
                                    &::after {
                                        background-color: $default-primary-base-color;
                                    }
                                }
                            }

                            .Menu-overlay {
                                flex-basis: 70%;
                                padding-inline-start: 20px;
                                display: flex;
                                justify-content: space-around;
                                
                                .Menu-ItemCaption {
                                    color: $btn-primary-color;
                                    font-size: 16px;
                                    font-weight: 600;
                                }
                            }

                            .Submenu {
                                position: initial;
                                padding: 0 0;
                                width: auto;
                                // inset-block-start: 0;
                                // inset-inline-start: 0;
                                transform: translate(0, 0);

                                @include desktop {
                                    margin-block-start: 15px;
                                }

                                a {
                                    color: $btn-primary-color;
                                    font-size: 12px;
                                    font-weight: 600;
                                    padding-block-end: 0px;
                                    pointer-events: all;
                                    display: flex;
                                    flex-wrap: wrap;
                                    gap: 10px;
                                    
                                    &:hover {
                                        color: $default-primary-base-color;
                                    }

                                    &::after {
                                        background-color: $default-primary-base-color;
                                    }

                                    .Menu-ItemCaption {
                                        color: $btn-primary-color;
                                        font-size: 12px;
                                        font-weight: 600;

                                        &:hover {
                                            color: $default-primary-base-color;
                                        }
    
                                        &::after {
                                            background-color: $default-primary-base-color;
                                        }
                                    }
                                }

                                .menu-img, img {
                                    width: auto;
                                }

                                .Menu-overlay {
                                    display: block;
                                    padding-inline-start: 0;
                                    flex-basis: 100%;
                                }
                            }

                            &:hover {
                                ~ .Hovermenu {
                                    display: block;
                                    position: relative;
    
                                    &::after {
                                        content: "";
                                        position: fixed;
                                        inset-inline-start: 0px;
                                        width: 100vw;
                                        background: rgba(0,0,0,0.5);
                                        height: 100vh;
                                        inset-block-start: 100px;
                                    }
                                }
                            }
                        }

                        &:hover {
                            .Submenu {
                                &:first-of-type {
                                    display: flex;
                                }
                                
                                .Submenu {
                                    display: block;
                                }
                                
                                .Hovermenu {
                                    display: none;
                                }
                            }

                            // .Hovermenu {
                            //     display: block;
                            //     position: relative;

                            //     &::after {
                            //         content: "";
                            //         position: fixed;
                            //         inset-inline-start: 0px;
                            //         width: 100vw;
                            //         background: rgba(0,0,0,0.5);
                            //         height: 100vh;
                            //         inset-block-start: 100px;
                            //     }
                            // }
                            
                            // &:has(.Submenu) {
                            //     &::after {
                            //         content: "";
                            //         position: fixed;
                            //         inset-inline-start: 0px;
                            //         width: 100%;
                            //         background: rgba(0,0,0,0.5);
                            //         height: 100%;
                            //         inset-block-start: 100px;
                            //     }
                            // }
                        }

                        .Hovermenu-overlay {
                            display: none;
                        }
                    }

                    .Menu-Link {
                        &_isHovered {
                            .Menu-ItemCaption {
                                @include mobile {
                                    /* stylelint-disable-next-line declaration-no-important */
                                    margin-inline-start: -15px !important;
                                    margin-inline-end: -15px;
                                    background-color: #f7f7f7;
                                    /* stylelint-disable-next-line declaration-no-important */
                                    padding: 25px 20px !important;
                                    text-align: center;
                                    font-size: 14px;
                                    font-weight: 600;
                                }
                            }
                        }

                        @include desktop {
                            display: flex;
                            gap: 5px;
                            padding: 20px 0;
                            margin-block-start: 0;
                            margin-block-end: 0;
                        }

                        &:hover {
                            ~ .deskMenu {
                                position: relative;
                                width: 100%;
                                height: 100%;
                                
                                &::after {
                                    content: "";
                                    position: fixed;
                                    inset-inline-start: 0px;
                                    width: 100vw;
                                    background: rgba(0,0,0,0.5);
                                    height: 100vh;
                                    inset-block-start: 100px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &-CompareLinkWrapper {
        @include mobile {
            display: none;
        }
    }

    &-SubCategories {
        @include desktop {
            display: flex;
            flex-wrap: wrap;
            padding-inline-start: 0;
            padding-inline-end: 0;
        }

        .subMenu-banner {
            @include desktop {
                flex-basis: 30%;
                padding-inline-end: 20px;
            }

            .title {
                font-size: 14px;
                line-height: 20px;
                font-weight: 600;
                margin-block-start: 10px;
            }

            .link {
                font-size: 12px;
                color: $default-primary-light-color;
                font-weight: 400;
                line-height: 20px;
                text-decoration: underline;
            }
        }

        .Menu-SubMenu {
            flex-basis: 70%;
            padding-inline-start: 70px;
        }
    }

    &-ItemList {
        &_type_subcategory {
            @include desktop {
                /* stylelint-disable-next-line declaration-no-important */
                display: flex !important;
                justify-content: space-between;
            }
        }
    }
}
