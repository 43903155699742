.CmsPage {
    div[data-content-type='video'] {
        font-size: 0;

        .pagebuilder-video-inner {
            box-sizing: border-box;
            display: inline-block;
            width: 100%;
        }

        .pagebuilder-video-container {
            border-radius: inherit;
            overflow: hidden;
            padding-top: 56.25%;
            position: relative;
        }

        iframe,
        video {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        @media screen and (max-width: 1024px) {
            .pagebuilder-video-container {
                border-radius: inherit;
                overflow: inherit;
                padding-top: inherit;
                position: inherit;
            }
            iframe,
            video {
                height: inherit;
                left: inherit;
                position: inherit;
                top: inherit;
                width: 100%;
            }
        }

    }
}
