/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.PrintReciept {
    @media print {
        .page-break {
            margin-block: 30px;
            display: block;
            background-color: white;
        }
    }
}

.OrderId {
    @media print {
        font-size: 24px;
        font-weight: 700;
        margin-block-end: 15px;
    }
}

@page {
    size: A1 | A4 | A5 | landscape;
    margin: 20px;
}

@media print {
    .page-break {
        page-break-before: always;
        page-break-after: always;
    }
}
